import React, { Component } from 'react';


class Resize extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {

    window.addEventListener('resize',this.resize);
  
  }
  
  componentWillUnmount() {
      window.removeEventListener('resize',this.resize);
    }

  resize = () =>{
  
    if(this.props.onResize) this.props.onResize();

  }

  /*

    RENDER

  */

  render() {

    return this.props.children

  }
}

export default Resize;
