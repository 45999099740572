import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import $ from 'jquery';
import Content from '../Content';

    class Form extends Component {
      constructor() {
        super();
        this.state = {
          errors : []
        };
      }


      onChange = (e) => {
        // Because we named the inputs to match their corresponding values in state, it's
        // super easy to update the state

        const state = this.state;

        // Checkboxes are true/false
        if(e.target.type == 'checkbox'){
          state[e.target.name] = (e.target.checked) ? true : false;
        }else{
          state[e.target.name] = e.target.value;
        }
        this.setState(state);

        if(this.props.onChange) this.props.onChange({...this.state});

        if(this.props.uriMap && this.props.keychange){
          let map = this.props.uriMap(state);
          e.preventDefault();
          this.props.history.push(map);
          return;
        }

      }

      onSubmit = (e) => {

        
        // Get form data
        const data = {...this.state};

        // Basic API for onSubmit
        let _thisAPI = {
          setContent : this.setContent,
          setErrors:this.setErrors
        };

        // onSubmit get
        if(this.props.onSubmit) this.props.onSubmit(data,e,$,_thisAPI);
        if(this.props.uriMap){
          let map = this.props.uriMap(data);
          e.preventDefault();
          this.props.history.push(map);
          return;
        }

        let ajax = (!this.props.hasOwnProperty('ajax')) ? true : this.props.ajax;
        if(!ajax) return;

        e.preventDefault();

        if(!this.props.action) return;
      
   
        const _this = this;

        this.setState({loading:true});
        let postdata = {...this.state};

        delete postdata.loading;
        delete postdata.errors;

        $.post(this.props.action, postdata,function(response){


          if(response.errors && response.errors.length){
              if(_this.props.onError){

                let showErrors = _this.props.onError(response.errors,_this.state);
                if(showErrors){

                    _this.setState({'errors':[],'loading':false});

                }else{

                    _this.setState({'errors':response.errors,'loading':false});

                }

              }else{
                _this.setState({'errors':response.errors,'loading':false});
              }

          }else{
              _this.setState({'errors':[],'loading':false});
              if(response.success){

                  if(_this.props.onSuccess){

                      _this.props.onSuccess(response,_this.state,_this);

                  }


              }

          }

          if(_this.props.onResponse){

              _this.props.onResponse(response,_this.state,_this);

          }

        }).fail(function(xhr, status, error) {
          // error handling
          _this.setState({'errors':['An error was encountered'],'loading':false});
        });
      }

      setContent = (content) => {

          this.setState({content:content});

      }

      setErrors = (errors) => {
          // ['Error text goes here']
          this.setState({errors:errors});

      }

      componentWillMount = () => {

        let _this = this;
        let initState = {};
        this.props.fields.forEach((field) => {
            if(field.type == 'checkbox'){
              if(!_this.state.hasOwnProperty(field.name)) initState[field.name] = field.value;
            }else{
              if(!_this.state.hasOwnProperty(field.name)) initState[field.name] = field.value || '';
            }
        });

        this.setState(initState);

      }



      componentWillUpdate = (nextProps) => {


        let _this = this;
        let initState = {};

        let changed = false;

        // Send a key to the form as a prop to make it easier o work out if it is a new form or not
        // <Form key={window.location.href} fields{...} />
        if(nextProps.key){

            if(nextProps.key != this.props.key) changed = true;

        }
        
        // If there's a new field, it's changed...
        nextProps.fields.forEach((field) => {

            if(!_this.state.hasOwnProperty(field.name)){
              changed = true;
            }else if(_this.state[field.name] != field.value){
              // Taking this off because it was emptying the form on every submission /setState call
              //changed = true;
            }

        });


        if(!changed) return false;

        // Form has changed, unset the fields and reset them based on props
        this.props.fields.forEach((field) => {

            initState[field.name] = undefined;

        });
        nextProps.fields.forEach((field) => {

            initState[field.name] = field.value;

        });

        this.setState(initState);

      }

      render() {

        if(this.state.content) return this.state.content;

        let _this = this;
        let submitText = this.props.submit || this.props.callToAction || 'Go'

        let loadingClass = (this.state.loading) ? 'Form--loading' : '';
        let ajax = (!this.props.hasOwnProperty('ajax')) ? true : this.props.ajax;


        let target = (!this.props.hasOwnProperty('target')) ? null : this.props.target;

        return (
          <React.Fragment>
          {this.props.content || null}
          <form action={((ajax)? null : this.props.action)} method={this.props.method||null} target={target} className={"Form Form--"+this.props.modifier+' '+loadingClass} id={this.props.id} onSubmit={this.onSubmit}>
            


            {this.state.errors.map((error,ix) => (
              <div key={'e_'+ix} className="Form-error">{error}</div>
            ))}

            <div className="Form-heading">{this.props.heading}</div>

            {this.props.fields.map((field,ix)=>{

                let title = (field.hasOwnProperty('title')) ? <div className="Form-field-title">{field.title}</div> : null;
                let description = (field.hasOwnProperty('description')) ? <div className="Form-field-description"><Content html={field.description} /></div> : null;
                let modifier = (field.hasOwnProperty('modifier')) ? 'Form-field--'+field.modifier : '';
                
                let caption = (field.hasOwnProperty('caption')) ? <div className="Form-field-caption">{field.caption}</div> : null;
                let input = null;
                let placeholder = field.placeholder;

                if(field.hasOwnProperty('required')){
                  if(field.required){
                    modifier += ' Form-field--required';
                    placeholder += '*';
                  }
                }

                modifier += ` Form-field--${field.type}`;

                switch(field.type){
                  case 'content':
                  return field.content;
                  case 'textarea':
                  input = <textarea key={field.name} style={field.style||{resize:'none'}} name={field.name} placeholder={placeholder} onChange={_this.onChange}  value={this.state[field.name]} />
                  break;
                  case 'checkbox':
                  input = <label for={'f_'+field.name}><input id={'f_'+field.name} key={field.name} autoComplete="off" style={field.style||null} type={field.type} name={field.name} placeholder={placeholder} value={'1'} onChange={_this.onChange} checked={this.state[field.name]} /><span className="Form-field-label">{placeholder}</span><span className="Form-field-check" /></label>
                  break;
                  case 'select':
                  input = <select id={'f_'+field.name} key={field.name} autoComplete="off" style={field.style||null} type={field.type} name={field.name} value={this.state[field.name]} onChange={_this.onChange}><option key={`opt_${field.name}_default`} value={''}>{placeholder}</option>{field.initial_value.split(',').map((v,oix)=><option key={`opt_${field.name}_${oix}`} value={v}>{v}</option>)}</select>
                  break;
                  default:
                  input = <input key={field.name} autoComplete="off" style={field.style||null} type={field.type} name={field.name} placeholder={placeholder} value={this.state[field.name]} onChange={_this.onChange} />
                }
                return (<div key={'f_'+ix}className={"Form-field "+modifier} style={(field.type == 'hidden') ? {display:'none'} : null}>{title}{description}{input}{caption}</div>)

            })}
            <div className="Form-submit">
              <button className="Link Link--submit Link--underline" type="submit"><span>{submitText}</span></button>
            </div>
          </form>
          </React.Fragment>
        );
      }
    }

export default withRouter(Form);
