import React, { Component } from 'react';

import {DateFormat,PageElement,ScrollTo,SiteModifier,FadeIn,Template,Window,Media,Section,Content,ModifierSection,Item} from '../_core/components';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';

import {Helmet} from "react-helmet";

class Journal extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		//if(!entry) return null;

		if(!entry){
			entry = {

				'title':'Posts',
				'uri':'/posts/',
				'blocks':[]

			}
		}

		

		let title = (entry.metaTitle) ? entry.metaTitle : entry.title;

		let isHome = entry.uri == '/';

		let autoOpen = entry.uri.match('/thanks');
	
		return (
			[
			<Helmet>
                <meta property="og:description" content={entry.metaDescription}/>
    			<meta name="description" content={entry.metaDescription} />
    			<meta name="twitter:description" content={entry.metaDescription} />
				<meta name="keywords" content={entry.metaKeywords} />
            </Helmet>,
			<PageElement name={'main'} id={entry.id} key={entry.id}>
				<Template modifiers={[entry.type,entry.template]} title={title}>
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				{(()=>{

	                if(isHome){
	                  return (<SiteModifier auto modifier={'home'} />);
	                }else{
	                	return (<SiteModifier auto demodifier={'home'} />);
	                }

	              })()}

	              {(()=>{

	                if(autoOpen){
	                  return (<SiteModifier auto modifier={'open'} />);
	                }

	              })()}

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>{ entry.title }</h1>
							{(()=>{

			                    if(entry.postDate){
			                      return (<div className="Template-sub"><Content><p><DateFormat time={entry.postDate} format={'DD.MM.YY'} /><br />{entry.subtitle}</p></Content></div>)
			                    }

			                  })()}
						</div>
					</div>


					{/* Body */}
					{(()=>{
			
	                    if(entry.content && (entry.content.trim() != "<p></p>")){
	                      return (

	                      	<div className="Template-body">
								<div className="Container">
									<Content modifier={'basic'} html={entry.content} />
								</div>
							</div>

	                     )
	                  	}

	                  })()}
					

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">

							<Data>
								{
									({entries}) => {

										let journal = entries.filter(e=>(e.type == 'post'));
										journal = journal.map(e=>{

											let j = {...e};
											j.sortVal = (e.sticky ? '1' : '0') + e.postDate;
											return j;

										});
										journal = journal.propSort('sortVal',-1);
										
										let b = [...entry.blocks].concat([
											{
												type: 'items',
												items : journal.map((j)=>({
													linkTo:j.id

												}))
											}
										])

										

										return (<Project.Blocks blocks={b} entry={entry} modifiers={['debug']} />)
										//let journal = entries;
										return <ul>{journal.map(e=>(<li>{e.title} {e.type}</li>))}</ul>

									}
								}
							</Data>

							{/*<Project.Blocks blocks={[...blocks]} entry={entry} modifiers={['debug']} />*/}
						</div>
					</div>
				
				</Template>
			</PageElement>,
			// Modifiers: Use fallback
			<PageElement name={'modifiers'} useFallback />,
			// Blocks: Use fallback
			<PageElement name={'blocks'} useFallback />
			]
		)

	}
}

export default Journal;
