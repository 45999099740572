import React, {Component} from 'react'

import Base from '../BaseComponent';


class Section extends Component {

  render () {




    return (
      <Base family="Section" modifiers={this.props.modifiers}>
        <div className="Section-body">
          {this.props.children}
        </div>
      </Base>
    )
  }
}

export default Section
