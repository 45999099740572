import React, { Component } from 'react';
import Image from '../../components/Image';
import * as Media from '../../utils/Breakpoints';

class Video extends Component {

	constructor(props) {
    super(props);
    this.state = {playing:0};
  }

  componentDidMount() {



	}

	componentWillUnmount() {

	  }



  render() {

	const ratioStyle = {

  		paddingTop:(100 / (this.props.ratio))+'%',
      position:'relative'

  	}
  	const fluidStyle = {

  		width:'100%',
  		height:'100%',
      position:'absolute',
      top:0,
      left:0

  	}

  	const style = (this.props.fluid) ? fluidStyle : ratioStyle;
   

    let className = this.props.className || 'Video--vimeo ';

    if(this.state.playing) className += ' Vimeo--playing';

    //if(this.props.fluid) className += " Image--fluid";

    let posterStyle = {...fluidStyle};
  if(this.state.playing){
      posterStyle.opacity = 0;
      posterStyle.pointerEvents = 'none';
  }
    return (
      <div className={'Video '+className} ref="image" style={{...style,zIndex:1,height:0}}>
        <div className={'Video-poster'} onClick={()=>this.setState({playing:1})} style={{...posterStyle,zIndex:2}}>
          <Image src={this.props.poster} fluid={true} />
        </div>
        <div className={'Video-iframe'} style={{...fluidStyle,zIndex:1}}>
          {(()=>{
            if(this.state.playing){
              return (
                <iframe src={`https://player.vimeo.com/video/${this.props.video}?autoplay=1&byline=0&title=0`} width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              )
            }
          })()}
        </div>
      </div>
    );
  }
}

export default Video;
