import React, {Component} from 'react'

import Resize from '../../utils/Resize'

const NOTMOVED = -100;

class Blank extends Component {
	engine;
  renderer;
  w = 0;
  h = 0;
  decay = 0;
	mouseX = NOTMOVED;
	mouseY = NOTMOVED;
  lastX = NOTMOVED;
  lastY = NOTMOVED;
  firstMove = 1;
	item;

  constructor(props){
    super(props);
    this.state = { x:NOTMOVED,
          y:NOTMOVED,
          deltaX:0,
          deltaY:0,
          strength:{x:0,y:0},
          delta:{x:0,y:0},
          delta:0,
          distance:0,
          middle : {x:0,y:0,inverted:{x:0,y:0}},
          centre : {x:0,y:0}
        }

  }

	componentDidMount = () => {

		let tilt = (this.props.hasOwnProperty('useTilt')) ? this.props.useTilt : false;
    let move = (this.props.useWindow) ? document : this.refs['el'];
    let el = this.refs['el'];

//window.addEventListener('resize',this.resize);
	move.addEventListener('mousemove',this.mouseMove,{
  capture: true,
  passive: true
});
  el.addEventListener('mouseenter',this.resize,{
  capture: true,
  passive: true
});
  if(!tilt){
	 move.addEventListener('touchmove',this.touchMove,{
    capture: true,
    passive: true
  });

  }else{

    window.addEventListener("deviceorientation", this.tiltMove, {
  capture: true,
  passive: true
});
  }

  this.resize();
	this.loop();

  setTimeout(this.resize,100);

	}


	/*
	
	Remove listeners on unmount...

	*/

	componentWillUnmount() {
	    //window.removeEventListener(this.resize);
      let move = (this.props.useWindow) ? document : this.refs['el'];
      let tilt = (this.props.hasOwnProperty('useTilt')) ? this.props.useTilt : false;

      let el = this.refs['el'];
	    move.removeEventListener('mousemove',this.mouseMove);
      el.removeEventListener('mouseenter',this.resize);
      if(!tilt){
        move.removeEventListener('touchmove',this.resize);
      }else{
        window.removeEventListener("deviceorientation", this.tiltMove);
      }
	    this.stopLoop();
	  }

	   mouseMove = (e) => {

      this.lastX = this.mouseX;
      this.lastY = this.mouseY;

  		this.mouseX = (this.props.useWindow) ? e.pageX : e.offsetX;
  		this.mouseY = (this.props.useWindow) ? e.pageY : e.offsetY;

      if(this.firstMove){

        this.lastX = this.mouseX;
        this.lastY = this.mouseY;
        this.firstMove = 0;
        
      }



  	}

    tiltMove = (event) => {

      var isPortrait = (window.innerHeight > window.innerWidth);

      var x = (isPortrait) ? event.beta : event.gamma;  // In degree in the range [-180,180]
      var y = (isPortrait) ? event.gamma : event.beta; // In degree in the range [-90,90]

      // Boost the values since range of movement is unlikely to be full 0 - 180 (uncomfortable on a phone)
      x *= 2;
      y *= 2;

      // Normalise y to be in the range [0,180]
      y += 90;

    
      // Store previous values
      this.lastX = this.mouseX;
      this.lastY = this.mouseY;

      // Store new values
      this.mouseX = (this.w * (y/180));
      this.mouseY = (this.h * (x/180)) ;

      //document.getElementById('log').style.top = `50%`;
      //document.getElementById('log').style.left = `50%`;

      if(this.firstMove){

        this.lastX = this.mouseX;
        this.lastY = this.mouseY;
        this.firstMove = 0;
        
      }



    }

  	loop = () => {
    
    if(this.stopped) return;
    this.onFrame();
    this.ticker = requestAnimationFrame(this.loop)
  }

  startLoop = ()=> {
    this.stopped = 0;
    this.loop();
  }

  stopLoop = ()=> {
    this.stopped = 1;
    this.h = 0;
    this.w = 0;
    cancelAnimationFrame(this.ticker);


  }

  onFrame = () => {
  		
    let x,y;

    // Do nothing if not moved
    if((this.mouseX == this.state.x) && (this.mouseY == this.state.y)) return;

    if(this.props.ease && (this.state.x != NOTMOVED)){
      x =  Math.round(this.state.x + (this.props.ease*(this.mouseX - this.state.x)));
      y = Math.round(this.state.y + (this.props.ease*(this.mouseY - this.state.y)));
    }else{

      x =  this.mouseX;
      y = this.mouseY;

    }
      
      let w = this.w;
      let h = this.h;

      if(!h || !w){
        this.resize();
        let w = this.w;
        let h = this.h;

        if(!w || !h){
          this.stopLoop();
          setTimeout(this.startLoop,1000);
        }
        
      }


      let deltaX = x- this.lastX;
      let deltaY = y- this.lastY;
      
      let focus = this.props.focus || {x:0.5,y:0.5};

      let focalPoint = {x:(this.w * focus.x),y:(this.h * focus.y)}

      let strength = {
        x:x / (focalPoint.x),
        y:y / (focalPoint.y)
      }

      let a = x - focalPoint.x;
      let b = y - focalPoint.y;

      let middle = {x:0,y:0,inverted:{x:0,y:0}};
      let middleX = 0;
      let midXPoint = (this.w * 0.5);
      if(x >= midXPoint){
          middleX = (x - midXPoint) / midXPoint;
      }else{
          middleX = -(1-(x / midXPoint));
      }
      if(middleX == Infinity) middleX = 0;
      middle.x = middleX;

      let middleY = 0;
      let midYPoint = (this.h * 0.5);
      if(y >= midYPoint){
          middleY = (y - midYPoint) / midYPoint;
      }else{
          middleY = -(1-(y / midYPoint));
      }

      if(middleY == Infinity) middleY = 0;

      middle.y = middleY;

      middle.inverted = {
        x : (1 - middle.x),
        y: (1 - middle.y)
      }

      let centre = {x:0,y:0};

      let distance = Math.sqrt( a*a + b*b );
      let longest = (w > h) ? w : h;
      let shortest = (w < h) ? w : h;


      let distanceStrength = distance / longest;
      let xStrength = 0.5 * (a / shortest);
      let yStrength = 0.5 * (b / shortest);

      //distanceStrength *= Math.min(xStrength,1);
      //distanceStrength *= Math.min(yStrength,1);

      distanceStrength = xStrength + yStrength;

     

      let dist = {

          value : distance,
          strength : distanceStrength,


      }
      
      

     this.setState(
        { x:x,
          y:y,
          deltaX:deltaX,
          deltaY:deltaY,
          delta: deltaX + deltaY,
          w:this.w,
          h:this.h,
          strength:strength,
          distance:dist,
          middle : middle,
           centre : centre
        });

  		

  }

  	/*
	
	Store touch coordinates as mouse

	*/

	  touchMove = (e) => {

	  	var touch = e.touches[0] // reference first touch point for this event

      
      
      this.lastX = this.mouseX;
      this.lastY = this.mouseY;

  		this.mouseX = touch.clientX;
  		this.mouseY = touch.clientY;

      if(this.firstMove){

        this.lastX = this.mouseX;
        this.lastY = this.mouseY;
        this.firstMove = 0;

      }

  		if(this.mouseY > window.innerHeight) this.mouseY = window.innerHeight;



  	}

    resize = () => {

      if(this.props.useWindow){
        this.w = window.innerWidth;
        this.h = window.innerHeight;
        return;
      }

      let el = this.refs['el'];
    
      if(!el || !el.firstChild) return null;
      let offset = el.firstChild.getBoundingClientRect();


      this.w = offset.width;
      this.h = offset.height;



    }

  render () {

    return (
      <Resize onResize={this.resize}>
      <div className={this.props.className} ref="el">
        {this.props.children(this.state)}
      </div>
      </Resize>
    )
  }
}

export default Blank
