import React, { Component } from 'react';

import Data from '../../models/Data'

import {withStore} from '../../utils/Store';

import cookieAPI from './_cookieAPI.js';

import './Cookies.css';

/*

RENDER CONTENT IF COOKIE IS NOT SET:
----------------------
<Cookie hasCookie={0} name={'foo'}>
	{({cookieAPI,cookies})=>(
		<a href="#" onClick={()=>cookieAPI.set('foo',1)}>SET FOO</a>
	)}
</Cookie>

RENDER CONTENT IF COOKIE IS SET:
----------------------
<Cookie name={'foo'}>
	{({cookieAPI,cookies})=>(
		<a href="#" onClick={()=>cookieAPI.remove('foo')}>REMOVE FOO</a>
	)}
</Cookie>


*/




class Cookie extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		
		let hasCookies = this.props.data.hasOwnProperty('cookies');
	
		if(!hasCookies){
			let cookies = cookieAPI.getCookies();
			this.props.store.store('cookies',cookies);
		}

		if(this.props.auto){

			this.set(this.props.name,this.props.value);

		}

	}

	set = (key,value) => {

		if(!value){

			cookieAPI.deleteCookie(key); // 1 Year

		}else{
			cookieAPI.setCookie(key,value,365); // 1 Year
		}
		
		let cookies = cookieAPI.getCookies();
		this.props.store.store('cookies',cookies);



	}

	remove = (key) => {


		cookieAPI.deleteCookie(key); // 1 Year
		let cookies = cookieAPI.getCookies();
		this.props.store.store('cookies',cookies);

	}


	/*

		RENDER

	*/

	render() {

		let com = this;

		return(
		<Data require={['cookies']}>
			
			{({cookies})=>{

				let cookieName = this.props.name || null;
				let renderIfHasCookie = this.props.hasOwnProperty('hasCookie') ? this.props.hasCookie : 1;

				let hasCookie = cookies.hasOwnProperty(cookieName);

				if(cookieName && (renderIfHasCookie && !hasCookie) || (!renderIfHasCookie && hasCookie)) return null;

				let rendered = (typeof this.props.children == 'function') ? this.props.children({cookieAPI:com,cookies:cookies}) : this.props.children;

		        return (rendered) ? rendered : null;

				return <div onClick={() => {this.set(this.props.name,this.props.value)}}>COOKIES:{JSON.stringify(cookies)}</div>


			}}

		</Data>)


		/*
		
		let cookieName = this.props.name;
		let renderIfHasCookie = this.props.hasOwnProperty('hasCookie') ? this.props.hasCookie : 1;

		let hasCookie = getCookie(name);

		if(!hasCookie) return null;

		let rendered = (typeof this.props.children == 'function') ? this.props.children({cookie:com}) : this.props.children;

        return (rendered) ? rendered : null;*/
		

	}
}

export default withStore(Cookie);