import React, {Component} from 'react'
//import ReactDOMServer from 'react-dom/server';

import {
  withRouter
} from 'react-router-dom';

import {withStore} from '../../utils/Store';

/*

Add this to index.html
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/TweenMax.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/easing/EasePack.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/plugins/ScrollToPlugin.min.js"></script>    

*/

const TweenLite = window.TweenLite;
const Expo = window.Expo;

/*

TransitionEntry - cross fade one entry with another when the route changes

*/

class Transition extends Component {

  animating=0;
  transitioning=false;

  constructor(props) {

      super(props);
      this.state = {elA:null,elB:null}

  }

  componentDidMount() {

      this.setState({elA:this.props.children});
  }

  shouldComponentUpdate = () => {

      return true;
      return this.transitioning;

  }

 componentWillReceiveProps(nextProps) {
   
      const curTrigger = this.props.trigger;
      const nextTrigger = nextProps.trigger;

      let _this = this;

      if (curTrigger !== nextTrigger) {
        this.transitioning = true;

        if(nextProps.transition != "fade"){
          TweenLite.set(_this.refs.el,{height:`${this.refs.visibleContent.offsetHeight}px`});
          this.setState({elB: this.refs.visibleContent.innerHTML,elA:nextProps.children});
        }else{
          this.setState({elB: this.refs.visibleContent.innerHTML,elA:null});
        }
        _this.transition(nextProps);
      }
    }

  transition = (nextProps) => {
      const _this = this;

      //if(_this.animating) return;

      _this.animating = 1;

      let duration = this.props.duration || 0.5;
      if(this.props.duration === 0) duration = 0;

      if(typeof this.props.transition == 'function'){

          this.props.transition({a:this.refs.elA,b:this.refs.elB,complete: () => {
            _this.setState({

                elA:nextProps.children,
                elB:null

            })

            if(_this.props.onComplete) _this.props.onComplete();
          }});

          return;
            
      }



      switch(nextProps.transition){

          case 'crossfade':
          case 'crossFade':
          // Fade out & Fade In at same time
          

          TweenLite.set(_this.refs.el,{position:'relative'});
          TweenLite.set(_this.refs.elA,{display:'block',opacity:0});
          TweenLite.set(_this.refs.elB,{display:'block',position:'absolute',opacity:1,width:'100%',top:0,left:0});

          setTimeout(function(){

              let minH = Math.max(_this.refs.elA.offsetHeight,_this.refs.elB.offsetHeight);
              TweenLite.set(_this.refs.el,{height:`${minH}px`});

              TweenLite.to(_this.refs.elA,duration,{opacity:1,onComplete: function(){


              }});
              TweenLite.to(_this.refs.elB,duration,{opacity:0,onComplete: function(){

                  
                  _this.setState({

                     elA:nextProps.children,
                    elB:null

                  });
                 

                  TweenLite.set(_this.refs.elA,{opacity:1});
                  TweenLite.set(_this.refs.elB,{display:'none'});
                  TweenLite.set(_this.refs.el,{position:'',height:''});

                  if(_this.props.onComplete) _this.props.onComplete();

                 
              }});

          },50);

          
          
          break;
          case 'slideup':
          case 'slideUp':
          // Slide up and fade in
          

          TweenLite.set(_this.refs.el,{position:'relative'});
          TweenLite.set(_this.refs.elA,{display:'block',opacity:0,y:50});
          TweenLite.set(_this.refs.elB,{display:'block',y:0,position:'absolute',opacity:1,width:'100%',top:0,left:0});

         

          setTimeout(function(){

              let minH = Math.max(_this.refs.elA.offsetHeight,_this.refs.elB.offsetHeight);
              TweenLite.set(_this.refs.el,{height:`${minH}px`});

              TweenLite.to(_this.refs.elA,duration*1.5,{opacity:1,y:0,ease:Expo.easeOut,onComplete: function(){


              }});
              TweenLite.to(_this.refs.elB,duration,{opacity:0,y:-30,onComplete: function(){

                  
                  _this.setState({

                     elA:nextProps.children,
                    elB:null

                  });
                 

                  TweenLite.set(_this.refs.elA,{opacity:1});
                  TweenLite.set(_this.refs.elB,{display:'none'});
                  TweenLite.set(_this.refs.el,{position:'',height:''});
                  _this.refs.elB.style.transform = '';
                  _this.refs.elA.style.transform = '';

                  if(_this.props.onComplete) _this.props.onComplete();
                 
              }});

          },50);

          
          
          break;
          case 'slideleft':
          case 'slideLeft':
          // Slide left and fade in
          
          var scroll = window.scrollY;

          TweenLite.set(_this.refs.el,{position:'relative'});
          TweenLite.set(_this.refs.elA,{display:'block',opacity:0,x:100});
          TweenLite.set(_this.refs.elB,{display:'block',x:0,position:'absolute',opacity:1,width:'100%',top:0,left:0});

          setTimeout(function(){

              let minH = Math.max(_this.refs.elA.offsetHeight,_this.refs.elB.offsetHeight);
              TweenLite.set(_this.refs.el,{height:`${minH}px`});

              if(nextProps.resetscroll){

                //TweenLite.set(_this.refs.elB,{height:_this.refs.translate.offsetHeight+'px'});
                TweenLite.set(_this.refs.translate,{y:-scroll+'px'});
                TweenLite.to(window,0,{scrollTo:{y:0,autoKill:false}});
               
              }

              TweenLite.to(_this.refs.elA,duration,{delay:0,opacity:1,x:0,onComplete: function(){


              }});
              TweenLite.to(_this.refs.elB,duration,{delay:0,opacity:0,x:-100,onComplete: function(){

                  TweenLite.set(_this.refs.translate,{y:0});
                  _this.setState({

                    elA:nextProps.children,
                    elB:null

                  });
                 

                  TweenLite.set(_this.refs.elA,{opacity:1});
                  TweenLite.set(_this.refs.elB,{display:'none'});
                  TweenLite.set(_this.refs.el,{position:'',height:''});
                  _this.refs.elB.style.transform = '';
                  _this.refs.elA.style.transform = '';

                  if(_this.props.onComplete) _this.props.onComplete();
                 
              }});

          },25);

          
          
          break;
          case 'slideright':
          case 'slideRight':
          // Slide right and fade in
          var scroll = window.scrollY;

          TweenLite.set(_this.refs.el,{position:'relative'});
          TweenLite.set(_this.refs.elA,{display:'block',opacity:0,x:-100});
          TweenLite.set(_this.refs.elB,{display:'block',x:0,position:'absolute',opacity:1,width:'100%',top:0,left:0});

          setTimeout(function(){

              let minH = Math.max(_this.refs.elA.offsetHeight,_this.refs.elB.offsetHeight);
              TweenLite.set(_this.refs.el,{height:`${minH}px`});

              if(nextProps.resetscroll){
                //TweenLite.set(_this.refs.elB,{height:_this.refs.translate.offsetHeight+'px'});
                TweenLite.set(_this.refs.translate,{y:-scroll+'px'});
                TweenLite.to(window,0,{scrollTo:{y:0,autoKill:false}});
               
              }


              TweenLite.to(_this.refs.elA,duration,{opacity:1,x:0,onComplete: function(){


              }});
              TweenLite.to(_this.refs.elB,duration,{opacity:0,x:100,onComplete: function(){

                  TweenLite.set(_this.refs.translate,{y:0});
                  _this.setState({

                     elA:nextProps.children,
                    elB:null

                  });
                 

                  TweenLite.set(_this.refs.elA,{opacity:1});
                  TweenLite.set(_this.refs.elB,{display:'none'});
                  TweenLite.set(_this.refs.el,{position:'',height:''});
                  _this.refs.elB.style.transform = '';
                  _this.refs.elA.style.transform = '';

                  if(_this.props.onComplete) _this.props.onComplete();
                 
              }});

          },50);

          
          
          break;
          default:
          // Fade out -> fade In
          TweenLite.set(_this.refs.elA,{display:'none'});
          TweenLite.set(_this.refs.elB,{display:'block'});

          let durIn = (this.props.hasOwnProperty('in')) ? this.props.in : duration * 1.5;
          let durOut = (this.props.hasOwnProperty('out')) ? this.props.out : duration;

          TweenLite.to(_this.refs.el,durOut,{opacity:0,onComplete: function(){

                _this.setState({

                  elA:nextProps.children,
                  elB:null

              })

                if(nextProps.resetscroll){

                TweenLite.to(window,0,{scrollTo:{y:0,autoKill:false}});
              
           
                }

                TweenLite.set(_this.refs.el,{position:'',height:''});

                TweenLite.set(_this.refs.elB,{display:'none'});
                TweenLite.set(_this.refs.elA,{display:'block'});
                
                TweenLite.to(_this.refs.el,durIn,{delay:0.4,opacity:1,onComplete:function(){
                    
                    if(!nextProps.scrollhash) return;
                    let hash = window.location.hash || '';
      

                    let found = 0;
                    if(hash){
                      found = document.getElementById(hash.replace('#',''));
                    }

                    if(found) TweenLite.to(window,1.5,{scrollTo:{y:(found.offsetTop),autoKill:false},ease:Expo.easeInOut});

                    if(_this.props.onComplete) _this.props.onComplete();

                }});
                

          }});

      }
      
     

  }


  render () {



    return (
      <div ref="el" data-rel="transition">
  
        <div ref="elA" data-rel="transition-a">
        
          <div ref="visibleContent">{this.state.elA}</div>

        </div>
       
        <div ref="elB" data-rel="transition-b">
       
          { (this.state.elB) ? (<div ref="translate" dangerouslySetInnerHTML={{__html:this.state.elB}} />) : <div></div> }

        </div>
      </div>
    )
  }
}

export default Transition;