import GoogleAnalytics from 'react-ga';
const MAPSTYLES = require('./json/map.json');
const IS_PRODUCTION = (window.location.hostname == 'pensive-lalande-8ac5bd.netlify.com');
const CDN_HOSTNAME = '//berenjak.e-i-b.com';
const hostName = (IS_PRODUCTION) ? CDN_HOSTNAME : '';
const CACHEBUSTER = '?nc='+new Date().getTime();

const WP_mediaTransformer = (media,store) => {



	let assets = media.filter(item=>(!!item)).map(item => {

		let i = {...item};
		let media = i.media_details;



		if(!media) return {};

		if(!media.width) media.width = 0;
		if(!media.height) media.height = 0;

		let orientation = (media.width > media.height) ? 'landscape' : 'portrait';

		let a = {
	        id: i.id,
	        title: i.title.rendered,
	        filename: i.source_url.split('/').pop(),
	        url: i.source_url,
	        width: media.width,
	        height: media.height,
	        orientation: orientation,
	        sizes : {
				local : i.source_url,
	        	original : i.source_url
	        },
	        imageAnimation : [],
	        animationSpeed : 500,
	        inlineVideo : false,
	        videoUrl : ''
	    };

	    if(media.sizes && Object.keys(media.sizes).length){
	    	a.sizes = {
	            local: media.sizes.full.source_url,
	            large: media.sizes.full.source_url,
	            medium: (media.sizes.hasOwnProperty('large')) ? media.sizes.large.source_url : media.sizes.full.source_url,
	            small: (media.sizes.hasOwnProperty('medium_large')) ? media.sizes.medium_large.source_url : media.sizes.full.source_url,
	            original: media.sizes.full.source_url
	        }
	    }

	    if(i.acf && i.acf.imageAnimation && i.acf.imageAnimation.images.length){

	    	a.imageAnimation = i.acf.imageAnimation.images.map(i=>(i.image));
	    	if(i.acf.imageAnimation.animationSpeed && i.acf.imageAnimation.animationSpeed != '') a.animationSpeed = parseInt(i.acf.animationSpeed);

	    }

	    if(i.acf && i.acf.videoUrl && i.acf.videoUrl.length){

	    	a.videoUrl = i.acf.videoUrl;
	    	a.inlineVideo = i.acf.inlineVideo;

	    }

	

	    return a;

	})
	
	return assets;

}

const WP_pagesTransformer = (pages,store) => {



	let entries = pages.map(item => {

		let i = {...item};
		i.url = i.link.replace(/(http:|https:)?\/\/[^\/]+/,'');
		i.uri = i.url;

		if(i.id == store.frontpage.id){

			i.url = '/';
			i.uri = '/';
		}

		i.title = i.title.rendered;
		//i.template = 'default';
		i.content = i.content.rendered;
		i.postDate = i.date;

		if(i.template){
			i.template = i.template.replace('template-','').replace('.php','');
		}

		if(i.acf){
			i = Object.assign(i, i.acf);
			delete i.acf;	
		}

		if(i.blocks){
			i.blocks = i.blocks.map(block=>({...block,type:block.acf_fc_layout}))
		}

		if(i.ticker){

			i.ticker = i.ticker.map(item=>{

				let copy = {...item};

				if(item.linkTo || item.customLink){
					copy.uri = (item.customLink) ? item.customLink : item.linkTo.replace(CDN_HOSTNAME,'');
				}

				return copy;

			});

		}


		return i;

	})

	return (store.hasOwnProperty('posts')) ? entries.concat(store.posts) : entries;

}

const WP_menuTransformer = (menu) => {

	return menu.filter(item => (item.menu_item_parent == "0")).map(item => {

		let url = item.type == 'custom' ? item.url : item.url.replace(/(http:|https:)?\/\/[^\/]+/,'');
		let i = {
			id:item.ID,
			slug: item.title.replace(/\s+/g, '-').toLowerCase(),
			title : item.title,
			uri : url.replace(CDN_HOSTNAME,'')
		};

		return i;

	})

}

const WP_structuresTransformer = (structures) => {

	let transformed = {};
	Object.keys(structures).forEach(k=>{

		transformed[k] = WP_menuTransformer(structures[k]);

	})
	
	if(!transformed.main) transformed.main = [];
	if(!transformed.footer) transformed.footer = [];
	if(!transformed.secondary) transformed.secondary = [];
	if(!transformed.footer) transformed.footer = [];
	if(!transformed.shortcuts) transformed.shortcuts = [];
	if(!transformed.header) transformed.header = [];
	if(!transformed.locations) transformed.locations = [];
                  
	return transformed;
}



const settings = {

	SITENAME : 'Berenjak',
	CDN_HOSTNAME : CDN_HOSTNAME,
	MANIFEST : [
		{
		name:'frontpage',
		src: '//content.berenjaklondon.com/data.json'+CACHEBUSTER,
		type:'json',
		subset:'frontpage'
		},
		{
		name:'entries',
		src: '//content.berenjaklondon.com/entries.json'+CACHEBUSTER,
		type:'json',
		transformer : WP_pagesTransformer
		},
		{
		name:'assets',
		src: '//content.berenjaklondon.com/assets.json'+CACHEBUSTER,
		type:'json',
		transformer : WP_mediaTransformer
		},
		{
		name:'structures',
		src: '//content.berenjaklondon.com/data.json'+CACHEBUSTER,
		type:'json',
		subset : 'structures',
		transformer : WP_structuresTransformer
		},
		{
		name:'instagram',
		src: '//content.berenjaklondon.com/data.json'+CACHEBUSTER,
		subset : 'instagram',
		type:'json'
		}

	],
	GOOGLE_MAPS_API_KEY : 'AIzaSyAbF_GZr7PXuAlYehBIrEwFA3KPSFVWbvU',
	MAPSTYLES : MAPSTYLES,
	GA_ID: 'UA-122654684-1',
	GTM_ID : 'GTM-TM8HM4Z'// Tag Manger

}



/*
	Init GA
*/

GoogleAnalytics.initialize({

    trackingId: settings.GA_ID,
    debug: true

});

export default settings;


