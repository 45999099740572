import React, {Component} from 'react'

class AlphaGroup extends Component {

  render () {

      let groups = {};
      let groupKeys = [];

      let _this = this;
      this.props.items.forEach((item)=>{

          let text = _this.props.group ? item[_this.props.group] : item;
          let firstLetter = text.charAt(0);

          if(!groups.hasOwnProperty(firstLetter)){
            groups[firstLetter] = {letter:firstLetter,items:[]};
            groupKeys.push(firstLetter);
          }
          groups[firstLetter].items.push(item);




      });

      for(let k in groups){

          if(groups.hasOwnProperty(k)){

              groups[k].items.sort(function(a, b){

                 let aText = _this.props.group ? a[_this.props.group] : a;
                 let bText = _this.props.group ? b[_this.props.group] : b;

                if(aText < bText) return -1;
                if(aText > bText) return 1;
                return 0;
            })

          }

      }

      let sortedKeys = groupKeys.sort();

      let sortedGroups = sortedKeys.map(k=>(groups[k]));
      /*

      sortedGroups.forEach((group)=>{



          group.items.sort(function(a, b){

             let aText = _this.props.group ? a[_this.props.group] : a;
             let bText = _this.props.group ? b[_this.props.group] : b;

            if(aText < bText) return -1;
            if(aText > bText) return 1;
            return 0;
        })

      })*/

      //console.log('groups',groups,sortedGroups);
      //return null;
      return this.props.children(sortedGroups);

        
    
  }
}

export default AlphaGroup
