import React, {Component} from 'react'

import Site, {SiteModifier_} from '../../utils/Site';
import Icon from '../Icon';


class Burger extends Component {

  render () {

    let content = null;

    let burger = [<div key={0} className="Site-burger">
      <SiteModifier_ modifier="menu">
        <div className="Site-openMenu">
          <Icon type="burger" glyph={this.props.glyph} />
        </div>
      </SiteModifier_>
      <SiteModifier_ demodifier="menu">
        <div className="Site-closeMenu">
          <Icon type="burger-close" glyph={this.props.glyph} />
        </div>
      </SiteModifier_>
      </div>];
      
    if(this.props.children){

        content = (<SiteModifier_ demodifier="menu">
        <div className="Site-menu">
          {this.props.children}
        </div>
        </SiteModifier_>)

        burger.push(content);
    }


    return burger
  }
}

export default Burger
