import React, {Component} from 'react'

// Core
import Loader from '../../components/Loader';
import Site from '../../components/Site';

import {Entry,Asset} from '../../models';
import {Template,Modal} from '../../controllers';
import {withData,withSite} from '../../utils';


class StoryWrapper extends Component {

  

  constructor(props){

      super(props);
  }

  componentDidMount = () => {


  }

  componentDidReceiveProps = (nextProps) => {



  }
  

  componentWillMount = () => {

      const _this = this;


  }

  

  render() {

    let {story,manifest} = this.props;
    let Render = this.props.render;
    console.log('STORY',story,manifest,Render);
    return (

        <Site>
          <Loader manifest={manifest} loaded={
            (data) => {
            if(story.hasOwnProperty('onLoad')){
              return story.onLoad(Render,data,story.props);
            }else{
              return (<Render {...story.props} />)
            }
          }} />
        </Site>
          
    );
  }
}




export default StoryWrapper;
