import React, {Component} from 'react'

import {
  withRouter
} from 'react-router-dom';


class PageRequest extends Component {

  href=null;
  
  constructor(props) {

      super(props);


  }

  shouldComponentUpdate = (nextProps) => {
    
    if(window.location.href != this.href){
      this.href = window.location.href
      return true;
    }

    return false;

  }


  render () {
      

    return this.props.children;

  }
}

export default withRouter(PageRequest);
