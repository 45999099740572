 import React, {Component} from 'react'

import moment from 'moment';
//import 'moment-timezone';

import '../../helpers/Array';

class Filter extends Component {

  testValue = (a,b) => {

      a = ''+a;
      b = ''+b;

      return a.toLowerCase() == b.toLowerCase();

  }
  render () {

      const items = this.props.data || this.props.items;

      if(!items) return null;


      const _this = this;


      let filtered = items;


      /* Search query */


      if(this.props.search){

        let {fields} = this.props.search;
        let query = this.props.search.value;

        if(!fields) fields = ['title'];

        filtered = filtered.filter((i,ix) => {

            let match = 0;
            fields.forEach((field) => {

                if(i.hasOwnProperty(field)){
                    let didMatch;
                    var re = new RegExp(query,"gi");
                    if(Array.isArray(i[field])){
                      i[field].forEach((val)=> {

                          if(val.match(re)) didMatch = true;

                      })
                    }else{
                      
                      didMatch = (i[field]) ? i[field].match(re) : false;
                    }
                    
                    if(didMatch) match++;

                }

            });
            
            return match > 0;



        });

      }

      /* Date search after */

      if(this.props.after){

        let {fields,same} = this.props.after;
        if(!same) same = true; // Allow same dates to match
        let afterDate = this.props.after.value;

        let afterMoment = moment(afterDate);

        if(!fields) fields = ['postDate'];

        filtered = filtered.filter((i,ix) => {

            let match = 0;
            fields.forEach((field) => {

                let value;

                    if(field.indexOf('.') != -1){

                        // Dot notation e.g. 'ActiveFrom.date'
                        value = field.split('.').reduce((o,i)=>o[i], i);
                    }else{

                        // regular field e.g 'postDate'
                        value = i[field] || 0;

                    }

                if(value){
                    
                    let didMatch = (same) ? moment(value).isSameOrAfter(afterMoment,'day') : moment(value).isAfter(afterMoment,'day');
                    //console.log(i.title,afterDate,i[field],didMatch);
                    if(didMatch) match++;

                }

            });

            return match > 0;


        });

      }

      /* Date search before */

      if(this.props.before){

        let {fields} = this.props.before;
        let beforeDate = this.props.before.value;

        let beforeMoment = moment(beforeDate);

        if(!fields) fields = ['postDate'];

        filtered = filtered.filter((i,ix) => {

            let match = 0;
            fields.forEach((field) => {

                let value;

                    if(field.indexOf('.') != -1){

                        // Dot notation e.g. 'ActiveFrom.date'
                        value = field.split('.').reduce((o,i)=>o[i], i);
                    }else{

                        // regular field e.g 'postDate'
                        value = i[field] || 0;

                    }

                if(value){

                    let didMatch = moment(value).isBefore(beforeMoment,'day'); // true;
                    if(didMatch) match++;

                }

            });

            return match > 0;


        });

      }

      /* Basic filters as object of key/values */

      /*
          
          filters={{
              
              colour : ['red','blue'],
              size : ['large']

          }}

          filters={{
              colour:'blue'
          }}

          filters={{
              colour:{
                value:'blue',
                match: 'all'
              }
          }}

      */

      if(this.props.filters){

        let {filters} = this.props;
        let matchType = (this.props.match) ? this.props.match : 'any'; // all or any
        let applyFilters = {};
        let shouldFilter = 0;
        for(let k in filters){
            
            let isObject = (filters[k] === Object(filters[k]) && (!Array.isArray(filters[k])));
            let value = (isObject) ? filters[k].value : filters[k];

            if(value){

                applyFilters[k] = (isObject) ? filters[k] : {value:filters[k],match:'any'};
                shouldFilter = 1;

            }
        }

        //console.log('filters supplied',applyFilters);
        if(shouldFilter){
     
        //console.log('filtering',applyFilters);
        filtered = filtered.filter((i,ix) => {

            let matches = 0;
            let numFilters = 0;

            // colour,size
            for(let k in applyFilters){

                numFilters++;
                let match = false;

                // supplied criteria, ['blue','red'] or 'blue'
                let test = applyFilters[k].value;

                // 'all' or 'any'
                let testMatchType = applyFilters[k].match;

                if(!Array.isArray(test)) test = [test];

                let matchTest = 0;

                // data item has this prop e.g {colour:...}
                if(i.hasOwnProperty(k)){
                    
                    

                    // ['blue','red']
                    test.forEach((val) => {
                      
                      // e.g data has mutiple options {colour:['green','pink,'blue']}
                      if(Array.isArray(i[k])){
                          
                          i[k].forEach((item)=>{
                              if(_this.testValue(item,val)) matchTest++;
                          })

                      }else{

                          // Not array {colour:'blue'}
                          
                          if(_this.testValue(i[k],val)) matchTest++;

                      }
                  
                    });
                    
                    //console.log('CHECK',k,testMatchType,test,test.length,matchTest);
                    match = (testMatchType == 'all') ? (matchTest == test.length) : (matchTest > 0);
                    
                    // Incrememnt per property
                    if(match) matches++;

                }

            }

            //console.log('FILTERING',i,matches,matchType,numFilters);
            return (matchType == 'all') ? (matches == numFilters) : (matches > 0);


        });
        }
      }

      /*
        
        Custom filter as function prop

      */

      if(this.props.filter){

        filtered = filtered.filter((i,ix) => this.props.filter(i,ix));

      }

      /* 
        
        Limit

      */

      if(this.props.limit){

          filtered = filtered.limit(this.props.limit);

      }

      let response = null;



      if(this.props.render){

        response = filtered.map((item,ix) => {

        let rendered = _this.props.render(item,ix);

        return {...rendered,key:ix}


      });

      }

      if(this.props.results){

          response = _this.props.results(filtered);

      }


    return response;
  }
}

export default Filter
