import React, {Component} from 'react'
import Site, {SiteModifier_,withSite} from '../../utils/Site';

import moment from 'moment';
import 'moment-timezone';

class DateList extends Component {

	monent;	

	componentDidMount = () => {


	}

  render () {

  	let _this = this;
  	let timeZone = this.props.timezone || 'Europe/London';
  	let count = this.props.count || 14; // Default to 14 days
    let interval = this.props.interval || 'day';
  	let showFrom = (this.props.from) ? moment.tz(this.props.from,timeZone) : moment.tz(timeZone);
  	let showTo = (this.props.to) ? moment.tz(this.props.to,timeZone) : false;


  	let defaultLabels = {
	    sameDay: '[Today]',
	    nextDay: '[Tomorrow]',
	    nextWeek: 'dddd',
	    lastDay: '[Yesterday]',
	    lastWeek: '[Last] dddd',
	    sameElse: 'ddd MM.DD'
	}

  	let labels = this.props.labels || defaultLabels;

    //let direction = this.props.direction !== null ? this.props.direction : 1;



  	// America/New_York
  	// https://momentjs.com/timezone/
  	


  	this.moment = showFrom.clone();

  	showFrom.endOf(interval);

  	

    return (
    	
    	[...new Array(count)].map((i,ix) => {

    		let date = showFrom.clone().add(ix, interval);
    		


    		let callbackData = {

    			from : showFrom.clone(), // Start of the set moment object
    			date : date.clone(), // The current moment object
    			label : date.calendar(showFrom,labels), // Label of the item
    			moment : this.moment.clone(), // Time right now in the timezone
    			ix : ix
    		}

    		if(this.props.render){
    			let rendered = _this.props.render(callbackData,ix);
    			if(rendered) return {...rendered,key:ix}
    		
    		}

    		//return (<div className="Content"><p>{day.calendar(showFrom,labels)} {day.format('dddd, MMMM Do YYYY, h:mm:ss a')} {this.moment.format('dddd, MMMM Do YYYY, h:mm:ss a')}</p></div>)

    	})
    
    )
  }
}

export default DateList