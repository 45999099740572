import React, {Component} from 'react'
import normalizeWheel from 'normalize-wheel';

//import { TweenLite,Expo,Circ } from "gsap";

/*

Add this to index.html
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/TweenMax.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/easing/EasePack.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/plugins/ScrollToPlugin.min.js"></script>    

*/

let TweenLite = window.TweenLite;
let Expo = window.Expo;
let Circ = window.Circ;

class NormalizedScroller extends Component {

  bounds = {x:0,y:0};
  pos = {x:0,y:0}
  progress=0;
  setprogress = 0;
  // TOUCH
  animation;
  touchTime = 0;
  touchPos = { x: 0, y: 0 };
  touchStart = { x: 0, y: 0 };
  touchMoved = { x: 0, y: 0 };
  touchEnded = 0;
  moving = 0;
  dragging = false;

  touchStart = e => {
    if (this.animation) this.animation.kill();

    let d = new Date();
    this.touchTime = d.getTime();

    this.touchStart.x = e.touches[0].pageX;
    this.touchStart.y = e.touches[0].pageY;
    this.touchPos.x = e.touches[0].pageX;
    this.touchPos.y = e.touches[0].pageY;
    this.touchMoved = { x: 0, y: 0 };

    this.dragging = true;

  };

  touchMove = e => {
    const _this = this;
    let xMoved = this.touchPos.x - e.touches[0].pageX;
    let yMoved = this.touchPos.y - e.touches[0].pageY;

    let pos,diff;

    let useY = true;



    if (!this.props.horizontal || !useY) {
      if (yMoved != 0) {
        e.preventDefault();
        //e.stopPropagation();
      } else {
        // Not moving the y axis
        return;
      }

      this.touchPos.y = e.touches[0].pageY;

      pos = this.pos.y;
      diff = yMoved * (this.props.touchSensitivity || 1);

      pos += yMoved;

      this.move(yMoved);

    } else {
      if (xMoved != 0) {
        e.preventDefault();
        //e.stopPropagation();
      } else {
        // Not moving the y axis
        return;
      }

      this.touchPos.x = e.touches[0].pageX;

      pos = this.pos.x;
      diff = xMoved * 0.4;

      pos += xMoved;

      this.move(xMoved);
    }


    
  };

  touchEnd = e => {
    let _this = this;
    let d = new Date();
    let releaseTime = d.getTime();
    let moved, start, end, maxDuration,dir;

    let duration = releaseTime - this.touchTime;

    this.dragging = false;

    if (this.props.horizontal) {
      moved = this.touchPos.x - this.touchStart.x;
      start = this.touchPos.x;
      end = this.touchPos.x + moved * (this.props.touchSensitivity || 0.4);
      maxDuration = 300;
      dir = (start > end) ? 'left' : 'right';
    } else {
      moved = this.touchPos.y - this.touchStart.y;
      start = this.touchPos.y;
      end = this.touchPos.y + moved * (this.props.touchSensitivity || 0.4);
      dir = (start > end) ? 'up' : 'down';
      maxDuration = 300;

    }

    if(this.props.onSwipe){

        let res = this.props.onSwipe({dir})
        return;

    }

    const anim = { val: start };
   
    let animTime = duration * 0.005;
    
    if (duration < maxDuration && Math.abs(moved) > 2) {
      _this.animation = TweenLite.to(anim, animTime, {
        val: end,
        ease: Expo.easeOut,
        onUpdate: function() {
          var diff = anim.val - end;
        
         
          _this.move(diff);

          
        },
        onComplete: function() {
         
        }
      });
    }else{
      this.move(0);
    }
  };


  // END TOUCH

  componentDidMount() {
    let el = this.refs.el;

    // nonstandard: Chrome, IE, Opera, Safari
    el.addEventListener("mousewheel", this._onMouseWheel, false);
    // nonstandard: Firefox
    el.addEventListener("DOMMouseScroll", this._onMouseWheel, false);

    el.addEventListener("touchstart", this.touchStart, false);
    el.addEventListener("touchmove", this.touchMove, false);
    el.addEventListener("touchend", this.touchEnd, false);

    // Init the first move
    this.move(0);

  }

  componentWillUnmount() {
    let el = this.refs.el;

    // nonstandard: Chrome, IE, Opera, Safari
    el.removeEventListener("mousewheel", this.listen);
    // nonstandard: Firefox
    el.removeEventListener("DOMMouseScroll", this.listen);

    el.removeEventListener("touchstart", this.touchStart);
    el.removeEventListener("touchmove", this.touchMove);
    el.removeEventListener("touchend", this.touchEnd);
  }

  componentWillReceiveProps(nextProps){

      
      let el = this.refs.el;
      let _this = this;

      if(nextProps.hasOwnProperty('scroll')){
        let scroll = (nextProps.scroll > 1) ? -nextProps.scroll : nextProps.scroll; // make negative

        let time = (nextProps.hasOwnProperty('speed')) ? nextProps.speed : 1;
        let ease = nextProps.easing || Expo.easeOut;



        if(this.props.horizontal){

          TweenLite.to(el,time,{x:scroll,ease:ease});
          this.pos.x = scroll;
        }else{
          TweenLite.to(el,time,{y:scroll,ease:ease});
          this.pos.y = scroll;
        }
      }

      


      if(nextProps.hasOwnProperty('progress') && (typeof nextProps.progress != 'undefined')){

        let prog = (nextProps.progress > 1) ? nextProps.progress/100 : nextProps.progress; // make 0.5
        
        try {
            prog = (prog > 0) ? prog.toFixed(2) : 0;
        }
        catch(err) {
           prog = 0;
        }
        
        
        if(prog != this.setprogress){



          this.setprogress = prog;

          let scroll;
          if(this.props.horizontal){
            scroll = prog * this.bounds.x;
           
            TweenLite.to(el,0.6,{x:scroll,ease:Circ.easeOut});
            this.pos.x = scroll;
            
          }else{
            scroll = prog * this.bounds.y;
            TweenLite.to(el,0.6,{y:scroll,ease:Circ.easeOut});
            this.pos.y = scroll;
          }

        }
      }

      if(nextProps.hasOwnProperty('set')){
        let set = (nextProps.set > 1) ? -nextProps.set : nextProps.set; // make negative
        if(this.props.horizontal){
          TweenLite.set(el,{x:set});
          this.pos.x = set;
        }else{
          TweenLite.set(el,{y:set});
          this.pos.y = set;
      
        }
      }

      if(nextProps.hasOwnProperty('reset') || nextProps.key != this.props.key){
        let set = 0; // make negative
        if(this.props.horizontal){
          TweenLite.set(el,{x:set});
          this.pos.x = set;
        }else{
          TweenLite.set(el,{y:set});
          this.pos.y = set;
        }
      }


  }

	_onMouseWheel = (event) => {

    let shouldMove = 1;
    if(this.props.shouldMove) shouldMove = this.props.shouldMove();

    if(!shouldMove) return;

    event.preventDefault();

		const normalized = normalizeWheel(event);

    let move = normalized.pixelY;

    if(this.props.hasOwnProperty('axisAgnostic')){
      let horiz = Math.abs(normalized.pixelX) > Math.abs(normalized.pixelY);
      if(horiz) move = normalized.pixelX;
    }
    
    this.move(move,normalized.pixelY); // Scrolling up and down moves left and right, so send Y in as first value

  }

  move = (amount) => {
     
    	let el = this.refs.el;
      let width = (this.props.getWidth) ? this.props.getWidth() : this.refs.el.offsetWidth;
      let height = (this.props.getHeight) ? this.props.getHeight() : this.refs.el.offsetHeight;

      let viewportWidth = (this.props.getViewportWidth) ? this.props.getViewportWidth() : this.refs.wrapper.offsetWidth;
      let viewportHeight = (this.props.getViewportHeight) ? this.props.getViewportHeight() : this.refs.wrapper.offsetHeight;

      let manualUpdate = (this.props.hasOwnProperty('manualUpdate')) ?  this.props.manualUpdate : 0;

      if(this.props.window){

        viewportWidth = window.innerWidth;
        viewportHeight = window.innerHeight;
      }

      let maxX,maxY;
      let progress = 0;
      let cur;

      if(this.props.horizontal){

          cur = this.pos.x;
          let x = cur - amount;

          maxX = width - viewportWidth;
          //maxX = 2000;
          maxX = -maxX;

          this.bounds.x = maxX;
        
          if(x < maxX) x = maxX;
          if(x > 0) x = 0;

          this.pos.x = x;

          progress = Math.abs(x / maxX);
          this.progress = progress.toFixed(2);

          if(!manualUpdate) TweenLite.set(el,{x:x});


      }else{

          cur = this.pos.y;
          let y = cur - amount;

          maxY = height - viewportHeight;

          maxY = -maxY;

          this.bounds.y = maxY;

          if(y < maxY) y = maxY;
          if(y > 0) y = 0;


          this.pos.y = y;

          progress = Math.abs(y / maxY);
          this.progress = progress.toFixed(2);

          if(!manualUpdate) TweenLite.set(el,{y:y});
          

      }

      if(this.props.onUpdate) this.props.onUpdate({dragging:this.dragging,progress:progress,delta: amount,pos:this.pos,width:Math.abs(maxX),height:Math.abs(maxY)});

	}

	render () {

    return (
      <div ref="wrapper">
      	<div ref="el">
        { (this.props.scrollcontent) ? this.props.scrollcontent : this.props.children }
        </div>
        { (this.props.scrollcontent) ? this.props.children : null }
      </div>
    )
  }
}

export default NormalizedScroller
