import React, {Component} from 'react'
import {Data,SetData} from '../../models';

/*


e.g.

Defining global elements / fallback / defaults

- Use the fallback prop to define the element as the default
- Use the reset prop as a boolean expression as to whether to reload the default content

Props:

name 		: label for this region
fallback 	: whether this is the default / display element
reset		: conditions under which to load the default content

-------------------------------
<div data-grid-of>
	<div data-col-three-of="4">
	  <PageElement name={'main'} fallback>
	    <div>Default main content</div>
	  </PageElement>
	</div>
	<div data-col-one-of="4">
	  <PageElement name={'aside'} fallback reset={window.location.pathname == '/'}>
	      <div>Default aside value...</div>
	  </PageElement>
	</div>
</div>



Setting per-page elements

- From a render function, return an array of PageElements
- Use the id prop to control whether the Element should rerender (by default will use the window URL as the id)
- useFallback will set the value to the default value in the layout

Props:

name 		: label for this region
useFallback : whether to load the default content
id			: unique identifier to force re-rendering

-----------------------------

render() {

		return (

			[
			// Main: Use fallback
			<PageElement name={'main'} useFallback />,
			// Aside
			<PageElement name={'aside'} id={entry.id}>
				<div>Aside:basic</div>
			</PageElement>
			]
		)
}

// Element will persist to most recent value until changed or a reset condition is met

// Set the element to empty
<PageElement name={'main'} ></PageElement>

// Set the element to a new value
<PageElement name={'main'} >
	<div>New value</div>
</PageElement>

// Set the value to the default
<PageElement name={'main'} useFallback>

*/

const PageElement = ({name,id,useFallback,fallback,reset,children}) => {
  	
	let defaultContent = children;
	
	if(fallback){
	
  		return (
  			<Data>
	            {(data)=>{
	            	let el = data[name];
	                if(!el || el == 'default' || reset) return defaultContent;
	                let Element = ()=>(el);

	                //return null;

	                return (<Element />);      
	            }}
          	</Data>
    	);
  	}else{

  		let key = (id) ? id : window.location.href;
  		
  		if(useFallback) return (<SetData name={name} key={key} value={'default'} />);
  		return (

  			<SetData name={name} key={key}>
				{children}
			</SetData>

  		)

  	}

}

export default PageElement;
