import React, { Component } from 'react';

// GA Event Logger
import GoogleAnalytics from 'react-ga';
import {Form} from '../../_core/components';

class Newsletter extends Component {


  render() {
    return (

          <Form
          target={'_blank'}
          onSubmit={(data,e,$,API) => {

              e.preventDefault();
              GoogleAnalytics.event({
                  category: 'Subscriptions',
                  action: 'Subscription Submission'
              });

              if(!data.email_address || data.email_address == ''){

                API.setErrors(['Please provide an email']);
                return;

              }

              $.get({
                                  url: `https://jksrestaurants.com/wp-json/headless/v1/signup/?list=berenjak&email=${data.email_address}`,
                                  success: function(response) {
                                      
                                      console.log(data,response);

                                      if (response.result == "success") {
                                         
                                         // Success!
                                         API.setContent(<div className="Content Content--small"><p>Thanks for your submission!</p></div>);

                                      } else {
                                         
                                         // Didn't add to list
                                         API.setErrors(response.errors);
                                        
                                      }

                                      
                                  },
                                  error: function(XMLHttpRequest, textStatus, errorThrown){
                                    
                                   
                                    API.setErrors(['There was a problem submitting the form, please try again later']);

                                  }
                              });

          }}
          submit={(
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 10.5 9.3">
              <polygon points="5.7,0.7 5,1.3 8,4.2 0.8,4.2 0.8,5.1 8,5.1 5,8 5.7,8.6 9.8,4.6 "/>
            </svg>
          )}
          modifier={'newsletter'}
          ajax={false}
          action={'https://mxm.mxmfb.com/form/process/c/2609/f/56a5f9d8c284f'}
          fields={[
              {
                name: 'email_address',
                type: 'email',
                placeholder: this.props.callToAction || 'Your email address here'
              }
          ]}>
          </Form>
    );
  }
}

export default Newsletter;
