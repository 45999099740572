import React, {Component} from 'react'
import Base from '../BaseComponent'

class List extends Component {

  render () {

    return (
      <Base {...this.props} family="List" tag="ul" >
        { this.props.children }
      </Base>
    )
  }
}

export default List
