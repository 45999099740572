import React, { Component } from 'react';

import {
  withRouter
} from 'react-router-dom';

import {Link,Grid,PageElement,ScrollTo,SiteModifier,FadeIn,Template,Window,Media,Section,Content,ModifierSection,Item} from '../_core/components';
import {Each} from '../_core/utils';

import ActionLogger from '../_core/utils/ActionLogger';


// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';

import {withStore} from '../_core/utils';
import {Helmet} from "react-helmet";

class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if(!this.props.entry) return;
		if(this.props.entry.slug == 'drink'){
			if(this.props.data.menu != 'drinks') this.props.store.store('menu','drinks');
		}else{
			this.props.store.unstore('menu');
		}
	}

	componentWillUnmount() {

	}

	componentWillReceiveProps(nextProps) {

	if(!this.props.entry) return;

      let hash = (this.props.location.hash) ? this.props.location.hash : '';
      let nextHash = (nextProps.location.hash) ? nextProps.location.hash : '';

      const currentPage = this.props.location.pathname + hash;
      const nextPage = nextProps.location.pathname + nextHash;

      if ((currentPage !== nextPage)) {

        if(nextProps.entry.slug == 'drink'){
			if(this.props.data.menu != 'drinks') this.props.store.store('menu','drinks');
		}else{
			this.props.store.unstore('menu');
		}


      }

     

    }


	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];
		
		let title = (entry.metaTitle) ? entry.metaTitle : entry.title;
	
		return (
			[
			<Helmet>
                <meta property="og:description" content={entry.metaDescription}/>
    			<meta name="description" content={entry.metaDescription} />
    			<meta name="twitter:description" content={entry.metaDescription} />
				<meta name="keywords" content={entry.metaKeywords} />
            </Helmet>,
			<PageElement name={'main'} id={entry.id} key={'main'+entry.id}>
				<Template modifiers={['menu']} title={title}>


				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>{ entry.title }</h1>
						</div>
					</div>


					{/* Body */}
					<div className="Template-body">
						<div className="Container">
							<Content html={entry.content} />
							<Grid modifiers={['links']}>
			                  <Each items={entry.downloads} render={
			                    (item) => {
			                      let uri = item.file;
			                      return (
			                        <div className="Grid-item">
			                        <ActionLogger category={'download'} action={'click'} label={item.text}>
			                          <Link to={uri} className={'Link Link--callToAction Link--download'}>
			                            <span className="Link-body">
			                              {item.text}
			                            </span>
			                          </Link>
			                         </ActionLogger>
			                        </div>
			                      )

			                    }
			                  } />
			                  </Grid>
						</div>
					</div>

				
				
				</Template>
			</PageElement>,
			<PageElement name={'modifiers'} id={entry.id} key={'mod'+entry.id}>
				<SiteModifier demodifier={'noMenus,home'} modifier={'menus'} />
				
			</PageElement>,
			<PageElement name={'blocks'} id={entry.id} key={'blocks'+entry.id}>
				
				<Template>
				<div className="Template-blocks">
					<div className="Container">
						<Project.Blocks blocks={[...blocks]} entry={entry} modifiers={['debug']} />
					</div>
				</div>
				</Template>
				
			</PageElement>
			]
		)

	}
}

export default withRouter(withStore(Default));
