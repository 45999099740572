import React, {Component} from 'react'

import {DateFormat,Html,Form,Link,LoaderLink,Base,Map,List,IsVisible,Grid,Media,ModifierSection,Section,Window,Content} from '../../_core/components';
import {Entry} from '../../_core/models';
import {Each} from '../../_core/utils';

import '../../_core/helpers/String';

// Project
import * as Project from '../../components';

// Conf
import conf from '../../_core/_conf';

class Blocks extends Component {

  sections = [];
  componentDidMount = () => {

      

  }
  cleanUri = (uri) => {

      let settings = conf.get();
      return uri.replace(settings.CDN_HOSTNAME,'')

  }
  makeBlocks = () => {

      this.section = [];
      let blocks = this.props.blocks || [];

      if(!blocks.length){
          this.sections = [];
          return;
      }

      // Get the background positions...
      if(blocks[0].type != 'background') blocks.unshift({
        type:'background',
        style:'none'
      })

        let sections = [];
        let section = -1;

        blocks.forEach((block)=>{

            if(block.type == 'background'){

                section++;
                sections[section] = {...block,blocks:[]};
            }else{

                sections[section].blocks.push(block);

            }

        })

      this.sections = sections;
  }
  getBlock = (origBlock,entry) => {
      
      let _this = this;
      let settings = conf.get();

      let block = {...origBlock}

      switch(block.type){

            case 'globalBlock':
            return (


              <Entry id={block.block} render={
                  (globalBlock) => (

                    <Each items={globalBlock.pageContent} render={(item)=>(
                        _this.getBlock(item,entry)
                    )} />  
                  )
                } />

            )

            case 'layout':
            return (

              <div className="Block Block--layout" data-type={block.type}>
                <div className="Block-content">

                    {/* Custom layout */}
                    <Project.Layout layout={block} />
                
                </div>
              </div>

            )

            case 'anchor':
            let anchor = block.name.replace(/\s/gi,'_').toLowerCase();
            return (

              <a className="Block Block--anchor" name={anchor} id={anchor} />

            )

            case 'subnav':

            let subitems = (entry) ? entry.children : [];
            if(block.items && block.items.length) subitems = block.items;

            return (

              <div className="Block Block--subnav" data-type={block.type}>
                <div className="Block-content">
                    
                    {/* Sub Nav */}
                    <ul className={"List List--nav List--"+block.style}>
                      <Entry id={subitems} render={
                        (entry) => (

                          <li key={entry.id}><LoaderLink to={entry.uri} navlink exact={true} deactivate="#nav">{entry.title}</LoaderLink></li>

                        )
                      } />
                     
                    </ul>
                  
                </div>
              </div>

            )

            case 'newsletter':
           
            return (

              <div className={"Block Block--newsletter Block--"+block.size} data-type={block.type}>
                <div className="Block-content">
                    <Content html={block.text} modifier={'footer'} />
                    <Project.Newsletter callToAction={block.callToAction} />
                </div>
              </div>

            )

            case 'form' :
            return (

              <div className={"Block Block--newsletter Block--"+block.size} data-type={block.type}>
                <div className="Block-content">
                    <Content html={block.text} modifier={'footer'} />
                    <Form
                    modifier={'basic'}
                    onSubmit={(data) => {
     

                    }}
                    onSuccess={(response,data,form)=>{
                      form.setContent(
                        <div>Thank you for your message. We will get back to you as soon as possible.</div>
                      )
                    }}
                    submit={(
                      block.callToAction || 'Send'
                    )}
                    ajax={true}
                    action={'//send.webcdn.network/api/bn9smwo5af57Un8Nd9GN'}
                    fields={block.fields.map(field=>{

                        let f = {...field};
                        if(field.type == 'Single Line') f.type = 'text';
                        if(field.type == 'Multi Line') f.type = 'textarea';

                        if(!f.placeholder) f.placeholder = f.name;

                        f.name = f.name.replace(/[\s]+/gi,'_').toLowerCase();

                        return f;

                    }).concat([
                      {'name':'website',type:'hidden',value:'Berenjak'},
                      {'name':'sender',type:'hidden',value:'berenjaklondon'},
                      {'name':'_json',type:'hidden',value:'1'},
                      {'name' : '_valid[email]', type:'hidden',value:'required|valid_email'},
                      {'name' : '_valid[name]', type:'hidden',value:'required'}
                    ])}>
                    </Form>

                </div>
              </div>

            )

            case 'embed':
           
            return (

              <div className={"Block Block--embed Block--"+block.size} data-type={block.type}>

                  {(()=>{

                    if(block.oembed){
                      return <div className="Block-content" dangerouslySetInnerHTML={{__html:block.oembed}} />
                    }else{
                      return <div className="Block-content"><iframe frameborder="0" src={block.url} /></div>
                    }

                  })()}

              </div>

            )

            case 'section':
           
            return (

              <div className={"Block Block--rice Block--"+block.size} data-type={block.type}>

                <Project.RiceSection fillHeight={0.3} />

              </div>

            )

            case 'mapIllustration':
           
            return (

              <div className={"Block Block--illustration Block--"+block.size+" Block--"+block.style} data-type={block.type} />


            )


            case 'divider':
           
            return (

              <div className={"Block Block--divider Block--"+block.size} data-type={block.type} />

            )

            case 'image':
            let images = block.images ||[];
            return (

              <div className={"Block Block--image Block--"+block.size} data-type={block.type}>
                <div className="Block-content">
                    
                    {/* Image grid */}
                    <Grid modifiers={[block.size,block.alignment]}>
                    {images.map(image=>(
                      <div key={image.image} className="Grid-item">
                        <Media id={image.image} caption={image.caption} fluid={false} />
                      </div>
                    ))}
                    </Grid>
                  
                </div>
              </div>

            )

            case 'gallery':
            let items = block.images ||[];
            let settings = {slidesToShow:1,variableWidth:true};
            if(block.size == 'half'){
              settings.fade = true;
              settings.variableWidth = false;
              settings.centerMode = false;
            }
          
            return (

              <div className={"Block Block--carousel Block--"+block.style+" Block--"+block.size} data-type={block.type}>
                <div className="Block-content">
                    
                    {/* Carousel */}
                    <Grid modifiers={[]}>
                      <div className="Grid-item">
                      <Project.CarouselGallery modifiers={[block.style,block.size]} settings={settings}>
                        {items.map(
                          (item) => (

                          <div className="CarouselGallery-item">

                            {/* Item--carousel */}
                             <div className={"Item Item--carousel Item--"+block.style}>
                                          
                                <div className="Item-media">
                                  <Media id={item.image} fluid={true}  />
                                </div>

                                <div className="Item-body">
                                {(()=>{

                                  if((item.linkTo && item.linkTo.length) || (item.customLink && item.customLink.length)){

                                      let uri = (item.customLink) ? item.customLink : this.cleanUri(item.linkTo);

                                      return ( 
                                        
                                            <span><LoaderLink to={uri} deactivate={'#nav'}>{item.caption}</LoaderLink></span>
                                       
                                      )

                                  }else{
                                      return <span>{item.caption}</span>
                                  }

                                })()}
                                 </div>
                           

                            </div>
                             {/* End Item--carousel */}
                            
                            
                          </div>

                          )
                        )}
                      </Project.CarouselGallery>
                      </div>
                    </Grid>
                  
                </div>
              </div>

            )

            case 'item':
          
          
            return (

              <div className={"Block Block--item Block--"+block.size} data-type={block.type+'--'+block.style}>
                <div className="Block-content">
                    

                            {/* Item--block */}
                             <div className={"Item Item--block Item--"+block.style}>
                                          
                                <div className="Item-media">
                                  <Media id={block.image} ratio={(600/400)} fluid={false} fluid={false}  />
                                </div>
                                <div className="Item-content">
                                <div className="Item-head">
                                {(()=>{

                                  if((block.linkTo && block.linkTo.length) || (block.customLink && block.customLink.length)){

                                      let uri = (block.customLink) ? block.customLink : this.cleanUri(block.linkTo);

                                      return ( 
                                        
                                            <span><Link to={uri} deactivate={'#nav'}>{block.heading}</Link></span>
                                       
                                      )

                                  }else{
                                      return <span>{block.heading}</span>
                                  }

                                })()}
                                 </div>

                                 <div className="Item-body">
                                  <Content modifier="item" html={block.summary} />
                                 </div>

                                 <div className="Item-foot">
                                   {(()=>{

                                  if((block.linkTo && block.linkTo.length) || (block.customLink && block.customLink.length)){

                                      let uri = (block.customLink) ? block.customLink : this.cleanUri(block.linkTo);

                                      return ( 
                                        
                                            <span><Link to={uri} className="Link Link--callToAction" deactivate={'#nav'}>{block.callToAction}</Link></span>
                                       
                                      )

                                  }else{
                                      return <span>{block.callToAction}</span>
                                  }

                                })()}
                                 </div>
                                </div>

                            </div>
                             {/* End Item--block */}
                            
                            
                          </div>
    
              </div>

            )

            case 'text':
            let numLinks = (block.links) ? block.links.length : 0;
            return (

              <div className={"Block Block--text Block--"+block.size+"  Block--"+block.style} data-type={block.type}>
                <div className="Block-content">

                  {(()=>{

                    if(block.heading){
                      return (<div className="Block-heading">{block.heading}</div>)
                    }

                  })()}

                  {/* Text / Article */}
                  <Grid modifiers={['article']}>
                    <div className="Grid-item"><Content modifier="article" html={block.text} /></div>
                    {(()=>{

                      if(block.caption){
                        return (<div className="Grid-item"><Content modifier="caption" html={block.caption} /></div>)
                      }

                    })()}
                    
                  </Grid>

                  <Grid modifiers={['links','links-'+numLinks]}>
                  <Each items={block.links} render={
                    (item) => {
                      let uri = (item.customLink && item.customLink.length) ? item.customLink : this.cleanUri(item.linkTo);
                      return (
                        <div className="Grid-item">
                          <LoaderLink to={uri} className={'Link Link--callToAction Link--'+block.style} deactivate={'#nav'}>
                            <span className="Link-body">
                              {item.callToAction}
                            </span>
                          </LoaderLink>
                        </div>
                      )

                    }
                  } />
                  </Grid>

                </div>
              </div>

            )

            case 'items':
            return (
              <div className="Block Block--items" data-type={block.type}>
                <div className="Block-content">

                  {(()=>{

                    if(block.heading){
                      return (<div className="Block-heading">{block.heading}</div>)
                    }

                  })()}

                  {/* Grid of items */}
                  <Grid modifiers={['items',block.size]}>
                  {block.items.length && block.items.map(entry=>(

                    <div className={"Grid-item Grid-item--"+(entry.size || 'default')}>
                      <div className={"Item Item--grid Item--"+block.style}>
                        <Entry id={[entry.linkTo]} render={

                            (link) => (

                              <React.Fragment>
                              <Link to={link.uri}>
                                <div className="Item-media">
                                  <Media id={entry.image || link.image || link.metaImage} fluid={false}  />
                                </div>

                                <div className="Item-body">
                                  <Content modifier="item">
                                    <h1>{(entry.title || link.title).decodeHTML()}</h1>
                                    <time datetime={link.postDate}><DateFormat time={link.postDate} format={'DD.MM.YY'} /></time>
                                    <p>{entry.summary || link.summary || null}</p>
                                  </Content>
                                </div>
                              </Link>
                              <div className="Item-foot">
                                <Link to={link.uri} className="Link Link--callToAction">Find Out More</Link>
                              </div>
                              </React.Fragment>

                            )

                        } />
                        
                      </div>
                    </div>

                  ))}
                  </Grid>
                </div>
              </div>

            )

            case 'definitionList':
            return (
              <div className="Block Block--definitionList" data-type={block.type}>
                <div className="Block-content">

                  {/* Grid of items */}
                  <dl className={"List List--definition List--"+block.style}>
                  <Each items={block.items} render={
                    (item) => (

                      <li className="List-item">
                        <dt>{item.itemTitle}</dt>
                        <dd>
                        <Content modifier={'definition'} html={item.text} />

                        <Entry id={item.linkTo} render={
                          (entry) => (
                            <LoaderLink to={entry.uri} className={'Link Link--definition Link--'+block.style} deactivate={'#nav'}>
                              <span className="Link-body">
                                {(item.callToAction && item.callToAction != '') ? item.callToAction : entry.title}
                              </span>
                            </LoaderLink>
                          )
                        } />
                 
                        </dd>
                      </li>

                    )
                  } />
                  </dl>
                </div>
              </div>

            )

            case 'bulletList':
            return (
              <div className="Block Block--bulletList" data-type={block.type}>
                <div className="Block-content">

                  {/* Grid of items */}
                  <ul className={"List List--bullet List--"+block.style}>
                  <Each items={block.items} render={
                    (item) => (

                      <li className="List-item">

                        <Content modifier={block.style}>
                          <Html html={item.itemTitle} />
                          <p className="List-detail">
                            {item.detail.decodeHTML()}
                          </p>
                        </Content>

                      </li>

                    )
                  } />
                  </ul>
                </div>
              </div>

            )

            case 'callToAction':

            let uri = (block.customLink && block.customLink.length) ? block.customLink : this.cleanUri(block.linkTo);

            return (

              <div className={"Block Block--callToAction Block--"+block.size} data-type={block.type}>
                <div className="Block-content">

                  {/* Link CTA / Call To Action */}
                
                    <LoaderLink to={uri} className={'Link Link--callToAction Link--'+block.style} deactivate={'#nav'}>
                      <span className="Link-body">
                        {(block.text && block.text != '') ? block.text : 'Click here'}
                      </span>
                    </LoaderLink>
                      
                  </div>
                </div>

            )

            case 'quote':
            return (
              <div className={"Block Block--quote Block--"+block.size} data-type={block.type}>
                <div className="Block-content">

                  {/* Quote */}
                  <div className={"Quote"}>
                    <div className="Quote-body">
                      <Content modifier="quote" html={block.text}/>
                    </div>
                    <div className="Quote-foot">
                      <Content modifier="quoteFoot" html={block.citation}/>
                    </div>
                  </div>

                </div>
              </div>
              )

            case 'map':
            return (
                <div className={"Block Block--map Block--"+block.size} data-type={block.type}>
                <div className="Block-content">

                  {/* Map */}
                  <div className={"Map"}>
                    <div className="Map-body">
                      <Map
                        lat={block.latitude}
                        lng={block.longitude}
                        zoom={17}
                        defaultUI={true}
                         />
                    </div>
                  </div>

                </div>
                </div>

            )

            default:
            return <div data-type={block.type} className={"Block Block--"+block.type+" Block--"+block.size}>{JSON.stringify(block)}</div>;
          }

  }
  render () {

    this.makeBlocks();
    //console.log('blocks',this.sections);

    let {sections} = this;
    let modifiers = this.props.modifiers || [];
    let entry = this.props.entry || false;

    return (
      <Base family="Blocks" modifiers={modifiers}>
        {sections.map((section) => {

            return (

               <div className={"Blocks-section Blocks-section--"+section.style}>
                    
                    {section.blocks.map((block) => {

                        return this.getBlock(block,entry);

                      })}

                </div>

            )

        })}

      </Base>
        

    )
  }
}

function Block(block){



}

export default Blocks
/*
  {blocks.map((block) => {

            if(block.type == 'text')
            {
              return (
                <Content html={block.body}/>
                )
            } else if(block.type == 'columns')
            {
              return (
              <div>
              <Content html={block.body}/>
              <div className="Template-column">
                <Content html={block.leftColumn}/>
              </div>
              <div className="Template-column">
                <Content html={block.rightColumn}/>
              </div>
              </div>)
            }

            })}
*/