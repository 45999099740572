import React, { Component } from "react";

// Conf
import conf from "./_core/_conf";

import GoogleTagManager from "./_core/components/GoogleTagManager";

// Core
import {
  ScrollTo,
  PageTitle,
  JumpLink,
  Cookie,
  MousePos,
  ScrollModifier,
  Image,
  Toggle,
  Form,
  Link,
  PageElement,
  Media,
  SiteModifier,
  Loader,
  Site,
  Grid,
  Navigation,
  Burger,
  Icon,
  Section,
  Content,
} from "./_core/components";
import Transition from "./_core/components/Transition";
import { Entry, Asset, Data } from "./_core/models";
import { Template, Modal } from "./_core/controllers";
import { withStore, withSite } from "./_core/utils";

import ActionLogger from "./_core/utils/ActionLogger";

// Project
import * as Project from "./components";

import Rice from "./components/RiceSection";

class Index extends Component {
  navIndex = 0;

  constructor(props) {
    super(props);
    this.state = { open: false, checkscroll: 0 };
  }

  componentWillMount = () => {
    const _this = this;
  };

  open = () => {
    this.setState({ open: true });
    // Set checkscroll state so that the DOM has loaded after the open state is set and there might be elements to scroll to
    // <ScrollTo />uses the checkscroll flag as a key to update and check the dom
    setTimeout(() => {
      this.checkscroll();
    }, 700);
  };

  checkscroll = () => {
    this.setState({ checkscroll: this.state.checkscroll + 1 });
  };

  render() {
    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let _this = this;

    /*return (

        <Loader manifest={settings.MANIFEST} wait={2000}
            loaded={
              () => (
                 <Data require={['structures']}>
                {(data)=>{
                    console.log(data);
                    return <div>Loaded</div>
                }}
                </Data>
              )}
        />

    );*/
    return (
      <Site>
        <Loader
          manifest={[
            { src: "/ui/img/logo.png" },
            { src: "/ui/img/texture/grain_1.png" },
            { src: "/ui/img/texture/grain_2.png" },
            { src: "/ui/img/texture/grain_3.png" },
          ]}
          wait={0}
          loaded={() => [
            <div className="Site-intro">
              <SiteModifier
                auto
                modifier={
                  "uri" +
                  window.location.pathname
                    .replace(/^\//, "")
                    .split("/")
                    .join(",uri")
                }
              />
              <div className="Site-splash" data-layout="fluid">
                <div className="Site-splash-intro">
                  <Content modifier="intro">
                    <p>
                      REINTERPRETING THE CLASSIC HOLE IN THE WALL EATERIES
                      LINING THE STREETS OF TEHRAN
                    </p>
                  </Content>
                </div>
                <div className="Site-splash-logo">
                  <div className="Logo" />
                </div>
                <div className="Site-splash-bg" data-layout="fluid">
                  {(() => {
                    return !this.state.open ? <Rice /> : null;
                  })()}
                </div>
                <div className="Site-splash-enter" onClick={this.open}>
                  <SiteModifier modifier={"open"} demodifier={"play"}>
                    <div className="Link Link--callToAction" />
                  </SiteModifier>
                </div>
              </div>
            </div>,

            <Loader
              manifest={settings.MANIFEST}
              wait={0}
              loaded={() => (
                <Data require={["structures"]}>
                  {({ entries, structures, instagram }) => {
                    let HOME = entries.find((e) => e.uri == "/");

                    //if(!this.state.open) return [<SiteModifier auto demodifier={'urithanks'} modifier={'preloaded'} />, <div className="Site-page"><Template /></div>];

                    return (
                      <div className="Site-page">
                        <ScrollModifier reverse={false} />
                        <ScrollTo
                          auto
                          offset={1}
                          duration={0}
                          checkAuto={this.state.checkscroll}
                        />

                        <SiteModifier
                          auto
                          demodifier={"urithanks"}
                          modifier={"preloaded"}
                        />
                        <div className="Site-head">
                          {/* Site Head Top */}
                          <div className="Site-head-top">
                            <div className="Site-logo">
                              <Link to={"/"}>
                                <div className="Logo">{settings.SITENAME}</div>
                              </Link>
                            </div>

                            <div className="Site-banner">
                              <div className="Banner">
                                <ul>
                                  {[...Array(10)].map((i, ix1) =>
                                    HOME.ticker.map((item, ix2) => (
                                      <li key={"banner_" + ix1 + "_" + ix2}>
                                        {(() => {
                                          if (item.uri) {
                                            return (
                                              <Link autotarget to={item.uri}>
                                                {item.title}
                                              </Link>
                                            );
                                          } else {
                                            return <span>{item.title}</span>;
                                          }
                                        })()}
                                      </li>
                                    ))
                                  )}
                                </ul>
                              </div>
                            </div>

                            <div className="Site-stamps">
                              <ul className="List List--stamps">
                                {structures.header.map((item, ix) => (
                                  <li key={"stamp_" + ix}>
                                    <ActionLogger
                                      category={"stamps"}
                                      action={"click"}
                                      label={item.title}
                                    >
                                      <Link autotarget to={item.uri}>
                                        <Icon glyph type={item.slug} />
                                        <span>{item.title}</span>
                                      </Link>
                                    </ActionLogger>
                                  </li>
                                ))}
                                <li key={"stamp_book"} id="bookstamp">
                                  <ActionLogger
                                    category={"stamps"}
                                    action={"click"}
                                    label={"Book"}
                                  >
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        document
                                          .getElementById("bookonline")
                                          .click()
                                      }
                                    >
                                      <Icon glyph type={"book-now"} />
                                      <span>{"Book Now"}</span>
                                    </a>
                                  </ActionLogger>
                                </li>
                              </ul>
                            </div>

                            <div className="Site-burger">
                              <Project.Burger />
                            </div>
                          </div>
                          {/* End Site Head Top */}

                          {/* Site Head Bottom */}
                          <div className="Site-head-bottom">
                            <div className="Site-nav">
                              <ul className="List List--nav">
                                {structures.main.map((item, ix) => (
                                  <li key={"nav_" + ix}>
                                    <ActionLogger
                                      category={"navigation"}
                                      action={"click"}
                                      label={item.title}
                                    >
                                      <Link navlink autotarget to={item.uri}>
                                        {item.title}
                                      </Link>
                                    </ActionLogger>
                                  </li>
                                ))}
                              </ul>
                            </div>

                            <div
                              className={
                                "Site-shortcuts Site-shortcuts--has" +
                                structures.shortcuts.length
                              }
                            >
                              <ul className="List List--icons">
                                {structures.shortcuts.map((item, ix) => (
                                  <li key={"shortcut_" + ix}>
                                    <ActionLogger
                                      category={"shortcuts"}
                                      action={"click"}
                                      label={item.slug}
                                    >
                                      <Link autotarget to={item.uri}>
                                        <Icon glyph type={item.slug} />
                                      </Link>
                                    </ActionLogger>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          {/* End Site Head Bottom */}
                        </div>

                        <div className="Site-burgerNav">
                          <SiteModifier demodifier="menu">
                            <div>
                              <ul className="List List--burger">
                                {structures.main.map((item, ix) => (
                                  <li key={"nav_" + ix}>
                                    <Link navlink autotarget to={item.uri}>
                                      {item.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                              <hr />
                              <ul className="List List--stamps">
                                {structures.header.map((item, ix) => (
                                  <li key={"stamp_" + ix}>
                                    <ActionLogger
                                      category={"stamps"}
                                      action={"click"}
                                      label={item.title}
                                    >
                                      <Link autotarget to={item.uri}>
                                        <Icon glyph type={item.slug} />
                                        <span>{item.title}</span>
                                      </Link>
                                    </ActionLogger>
                                  </li>
                                ))}
                                <li key={"stamp_book"}>
                                  <ActionLogger
                                    category={"stamps"}
                                    action={"click"}
                                    label={"Book"}
                                  >
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        document
                                          .getElementById("bookonline")
                                          .click()
                                      }
                                    >
                                      <Icon glyph type={"book-now"} />
                                      <span>{"Book Now"}</span>
                                    </a>
                                  </ActionLogger>
                                </li>
                              </ul>
                            </div>
                          </SiteModifier>
                        </div>

                        <div className="Site-template">
                          <Template>
                            {({ entry, found, template, uri, url }) => {
                              // Store the value of this URI relative to the main navigation items to set whether we move left or right to it
                              let navItem = structures.main.filter(
                                (i) => i.uri == uri
                              );
                              let indexWas = this.navIndex;
                              let navIndex = navItem.length
                                ? structures.main.indexOf(navItem[0])
                                : -1;
                              if (navIndex != indexWas)
                                _this.navIndex = navIndex;
                              if (!this.state.open && entry) {
                                let blocks = entry.blocks || [];
                                let content = entry.content || null;

                                return (
                                  <div>
                                    {content}
                                    <Project.Blocks
                                      blocks={blocks.filter(
                                        (b) => b.type == "text"
                                      )}
                                    />
                                  </div>
                                );
                              }
                              return (
                                <div>
                                  <Transition
                                    transition={
                                      indexWas < _this.navIndex
                                        ? "slideleft"
                                        : "slideright"
                                    }
                                    duration={0.5}
                                    resetscroll
                                    trigger={uri}
                                    onComplete={this.checkscroll}
                                  >
                                    <PageElement name={"main"} fallback>
                                      <div>
                                        Main: Default content{" "}
                                        {found
                                          ? entry.title
                                          : `(${template}.js template)`}
                                      </div>
                                    </PageElement>
                                  </Transition>
                                </div>
                              );
                            }}
                          </Template>
                        </div>

                        <PageElement name={"modifiers"} fallback>
                          <SiteModifier
                            modifier="noMenus"
                            demodifier={"menus"}
                          />
                        </PageElement>

                        <MousePos useWindow={true} ease={0.2}>
                          {(mousedata) => {
                            let foodNudgeX = 50 * mousedata.middle.inverted.x;
                            let drinkNudgeX = 80 * mousedata.middle.inverted.x;
                            let platterNudgeX =
                              10 * mousedata.middle.inverted.x;

                            let foodNudgeY = 50 * mousedata.middle.inverted.y;
                            let drinkNudgeY = 80 * mousedata.middle.inverted.y;
                            let platterNudgeY =
                              10 * mousedata.middle.inverted.y;

                            return (
                              <div>
                                <div className="Site-menusContainer">
                                  <div className="Site-menus">
                                    <div className="Menus">
                                      <div
                                        className={
                                          "Menus-food " +
                                          (this.props.store.get("menu") ==
                                          "food"
                                            ? "is-active"
                                            : "")
                                        }
                                        onClick={() => {
                                          this.props.store.toggle(
                                            "menu",
                                            "food"
                                          );
                                        }}
                                      >
                                        <div
                                          className="Menus-item"
                                          style={{
                                            transform: `translate(${foodNudgeX}px,${foodNudgeY}px)`,
                                          }}
                                        >
                                          <Media
                                            id={HOME.foodMenu}
                                            original
                                            fluid={false}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          "Menus-drinks " +
                                          (this.props.store.get("menu") ==
                                          "drinks"
                                            ? "is-active"
                                            : "")
                                        }
                                        onClick={() => {
                                          this.props.store.toggle(
                                            "menu",
                                            "drinks"
                                          );
                                        }}
                                      >
                                        <div
                                          className="Menus-item"
                                          style={{
                                            transform: `translate(${drinkNudgeX}px,${drinkNudgeY}px)`,
                                          }}
                                        >
                                          <Media
                                            id={HOME.drinksMenu}
                                            original
                                            fluid={false}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          "Menus-platters " +
                                          (this.props.store.get("menu") ==
                                          "platters"
                                            ? "is-active"
                                            : "")
                                        }
                                        onClick={() => {
                                          this.props.store.toggle(
                                            "menu",
                                            "platters"
                                          );
                                        }}
                                      >
                                        <div
                                          className="Menus-item"
                                          style={{
                                            transform: `translate(${platterNudgeX}px,${platterNudgeY}px)`,
                                          }}
                                        >
                                          <Media
                                            id={HOME.platterMenu}
                                            original
                                            fluid={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* Stamp */}
                                    <div className="Site-stamp--menu" />
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        </MousePos>

                        <PageElement name={"blocks"} fallback>
                          <div />
                        </PageElement>

                        <hr />
                        <div className="Site-instagram">
                          <div className="Container Container--centered">
                            <Content modifier={"instagram"}>
                              <h1>
                                <a
                                  href="https://instagram.com/berenjaklondon"
                                  target="_blank"
                                >
                                  @berenjaklondon
                                </a>
                              </h1>
                              <ul className="List List--instagram">
                                {instagram.hasOwnProperty("data") &&
                                  instagram.data.limit(5).map((item, ix) => (
                                    <li key={"instagram_" + ix}>
                                      <a
                                        href="https://instagram.com/berenjaklondon"
                                        target="_blank"
                                        className="Item Item--instagram"
                                      >
                                        <div className="Item-media">
                                          <Image
                                            src={
                                              item.images.standard_resolution
                                                .url
                                            }
                                            fluid={false}
                                            ratio={1}
                                          />
                                        </div>
                                        <div className="Item-body">
                                          <span>
                                            {item.caption.text.length > 70
                                              ? item.caption.text.substring(
                                                  0,
                                                  70
                                                ) + "..."
                                              : item.caption.text}
                                          </span>
                                        </div>
                                      </a>
                                    </li>
                                  ))}
                              </ul>
                              <h3>
                                <a
                                  href="https://instagram.com/berenjaklondon"
                                  target="_blank"
                                >
                                  Follow us
                                </a>
                              </h3>
                              <ul className="List List--icons">
                                {structures.social.map((item, ix) => (
                                  <li key={"social_" + ix}>
                                    <ActionLogger
                                      category={"social"}
                                      action={"click"}
                                      label={item.slug}
                                    >
                                      <Link autotarget to={item.uri}>
                                        <Icon type={item.slug} />
                                      </Link>
                                    </ActionLogger>
                                  </li>
                                ))}
                              </ul>
                            </Content>
                          </div>
                        </div>
                        <hr />

                        <div className="Site-foot">
                          <div className="Container">
                            <div className="Grid Grid--footer">
                              <div>
                                <div
                                  className="Site-icon-address"
                                  onClickOff={() => {
                                    window.open(
                                      "https://www.google.co.uk/maps/place/Berenjak/@51.512981,-0.1331195,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xbc560d9169dcb066!8m2!3d51.512981!4d-0.1309308?shorturl=1"
                                    );
                                  }}
                                >
                                  <Content
                                    modifier={"footer"}
                                    html={HOME.footerContact}
                                  />
                                </div>

                                <div
                                  className="Site-icon-email"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    window.location.href = `mailto:${HOME.globalEmail}`;
                                  }}
                                >
                                  <Content modifier={"footer"}>
                                    <p>
                                      <a href={`mailto:${HOME.globalEmail}`}>
                                        {HOME.globalEmail}
                                      </a>
                                    </p>
                                  </Content>
                                </div>
                                {(() => {
                                  if (HOME.globalPhone) {
                                    return (
                                      <div
                                        className="Site-icon-phone"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          window.location.href = `tel:${HOME.globalPhone}`;
                                        }}
                                      >
                                        <Content modifier={"footer"}>
                                          <p>
                                            <a href={`tel:${HOME.globalPhone}`}>
                                              {HOME.globalPhone}
                                            </a>
                                          </p>
                                        </Content>
                                      </div>
                                    );
                                  }
                                })()}

                                {/*
                                <Content modifier={'footer'}>
                                  <nav>
                                    <hgroup>
                                      <h1>About</h1>
                                      <ul>
                                        {structures.secondary.map((item,ix)=>(
                                            <li key={'secondary_'+ix}><Link autotarget to={item.uri}>{item.title}</Link></li>
                                        ))}
                                      </ul>
                                    </hgroup>
                                  </nav>
                                </Content>
                                */}
                              </div>
                              <div>
                                <Content
                                  modifier={"footer"}
                                  html={HOME.footerMiddle}
                                />
                              </div>
                              <div>
                                <Content
                                  modifier={"footer"}
                                  html={HOME.footerNewsletter}
                                />

                                <Project.Newsletter />
                              </div>
                            </div>

                            <div className="Grid Grid--footer">
                              <div>
                                <Content modifier={"legal"}>
                                  <ul className="List List--footer">
                                    {structures.footer.map((item, ix) => (
                                      <li key={"secondary_" + ix}>
                                        <Link autotarget to={item.uri}>
                                          {item.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </Content>
                              </div>
                              <div>
                                <ul className="List List--icons">
                                  <li
                                    onClick={() =>
                                      this.setState({ open: false })
                                    }
                                  >
                                    <SiteModifier modifier="play">
                                      <a href={"javascript:;"}>
                                        <Icon glyph type={"rice"} />
                                      </a>
                                    </SiteModifier>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <Content modifier={"legal"}>
                                  <p>
                                    ©{" "}
                                    <a
                                      href="https://www.jksrestaurants.com/"
                                      target="_blank"
                                    >
                                      JKS Restaurants
                                    </a>{" "}
                                    {new Date().getFullYear()}
                                  </p>
                                </Content>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={"Site-mobileShortcuts"}>
                          <ul
                            className={
                              "List List--icons List--has" +
                              structures.shortcuts.length
                            }
                          >
                            {structures.shortcuts.map((item, ix) => (
                              <li key={"mobile_shortcut_" + ix}>
                                <ActionLogger
                                  category={"mobile_shortcuts"}
                                  action={"click"}
                                  label={item.slug}
                                >
                                  <Link autotarget to={item.uri}>
                                    <Icon glyph type={item.slug} />
                                  </Link>
                                </ActionLogger>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {/* Site Book */}
                        <div className="Site-book">
                          <ul className="List List--stamps">
                            <li>
                              <ActionLogger
                                category={"mobile_booking"}
                                action={"click"}
                                label={"Book"}
                              >
                                <a
                                  href="javascript:;"
                                  onClick={() =>
                                    document
                                      .getElementById("bookonline")
                                      .click()
                                  }
                                >
                                  <Icon glyph type={"book-now"} />
                                  <span>{"Book"}</span>
                                </a>
                              </ActionLogger>
                            </li>
                          </ul>
                        </div>

                        {/* Site message */}
                        {(() => {
                          if (HOME.showPopup) {
                            return (
                              <div className="Site-message">
                                <div className="Site-message-content">
                                  <SiteModifier modifier={"noMessage"}>
                                    <a className="Site-close">
                                      <Icon glyph type={"cross"} />
                                    </a>
                                  </SiteModifier>

                                  <Content html={HOME.popupMessage} />
                                </div>

                                <SiteModifier modifier={"noMessage"}>
                                  <div className="Site-message-bg" />
                                </SiteModifier>
                              </div>
                            );
                          }
                        })()}
                        {/* End site message */}

                        <JumpLink to={window.innerHeight}>
                          <div className="Site-scrollDown" />
                        </JumpLink>

                        {/* Cookie Message */}

                        <div className="Site-cookies">
                          <Cookie hasCookie={0} name={"agreeCookies"}>
                            {({ cookieAPI, cookies }) => (
                              <SiteModifier
                                auto
                                modifier={"withCookieMessage"}
                              />
                            )}
                          </Cookie>
                          <Cookie hasCookie={1} name={"agreeCookies"}>
                            {({ cookieAPI, cookies }) => (
                              <SiteModifier
                                auto
                                demodifier={"withCookieMessage"}
                              />
                            )}
                          </Cookie>

                          <Cookie>
                            {({ cookieAPI, cookies }) => (
                              <Content modifier="notice">
                                <p>
                                  We have placed cookies on your device. For
                                  more information about our use of cookies,
                                  please see our{" "}
                                  <Link to={"/cookies"}>Cookies</Link> and{" "}
                                  <Link to={"/privacy-policy"}>
                                    Privacy Notice
                                  </Link>
                                  . Unless you change your cookie settings, we
                                  assume you're ok to continue.
                                </p>
                                <p>
                                  <a
                                    href="javascript:;"
                                    className="Link Link--button"
                                    onClick={() =>
                                      cookieAPI.set("agreeCookies", 1)
                                    }
                                  >
                                    I Agree
                                  </a>
                                </p>
                              </Content>
                            )}
                          </Cookie>
                        </div>

                        {/* Google Tag manager */}
                        <GoogleTagManager gtmId={settings.GTM_ID} />
                      </div>
                    );
                  }}
                </Data>
              )}
            />,
          ]}
        />
      </Site>
    );
  }
}

export default withStore(Index);
