import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Link,withRouter
} from 'react-router-dom';

import Site, {SiteModifier_,withSite} from '../../utils/Site';


import Icon from '../../components/Icon';

// Templates

const Templates = {

}
const req = require.context('../../../modals', false, /\.js$/);
req.keys().forEach(filename => Templates[filename.replace('./','').replace('.js','')] = req(filename).default);



class Modal extends Component {

	static contextTypes = {
    	router: PropTypes.object, // replace with PropTypes.object if you use them
  	}
	pageVisits=0;

	constructor(props) {
		super(props);
		this.state = {template:null,content:null};
	}

	goBack = (ev) => {

		ev.preventDefault();
		this.context.router.history.goBack();

	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	shouldComponentUpdate(nextProps){

		let url = this.props.location.pathname+this.props.location.hash;
		let nextUrl = nextProps.location.pathname+nextProps.location.hash;
		
		return (url != nextUrl);

	}

	componentWillReceiveProps(nextProps) {
      let hash = (this.props.location.hash) ? this.props.location.hash : '';
      let nextHash = (nextProps.location.hash) ? nextProps.location.hash : '';

      const currentPage = this.props.location.pathname + hash;
      const nextPage = nextProps.location.pathname + nextHash ;

      if (currentPage !== nextPage) {
        this.pageVisits++;
      }
    }

	/*

		RENDER

	*/

	render() {

		let backUrl = this.props.location.pathname;
		let hash = this.props.location.hash.replace(/#\/?/,'');

		//console.log('MODAL LOC~ATION',this.props);

		let request = hash.split('?');
		let segments = request[0].split('/');
		let template = segments[0];

		let query = request[1];
		let args = (query) ? query.split('&') : [];

		let params = args.reduce((cur,arg) => {
			//console.log(arg,cur);
			let pair = arg.split('=');

			cur[pair[0]] = pair[1];
			return cur;

		},{});



		let found = false;
		let Output;
		let content = null;

		if(Templates.hasOwnProperty(template)){

			found = 1;
			Output = Templates[template];

			content = <Output params={params} segments={segments} />;

		}else{

			found = false;

		}

		let active = (found) ? 'is-active' : 'is-inactive';

		let modifier;

		if(found){

			modifier = <SiteModifier_ auto={true} modifier="modal" />

		}else{

			modifier = <SiteModifier_ auto={true} demodifier="modal" />

		}

		let backArrow;
		if(this.pageVisits){

			backArrow = (<a onClick={this.goBack} className="Modal-back">
				<Icon type="arrow-left" />
			</a>)

		}else{

			backArrow = null;

		}

		let closeButton = {icon:null,cover:null}

		if(this.props.close == 'back' && (this.pageVisits > 0)){

			// Close button will take you back one step
			closeButton.icon = (<a style={{cursor:'pointer'}} href={backUrl} onClick={this.goBack} className="Modal-close">
					<Icon type="cross" />
				</a>);
			closeButton.cover = (<a style={{cursor:'pointer'}} href={backUrl} onClick={this.goBack} className="Modal-coverClose" />);

		}else{

			// Close button will be added to history
			closeButton.icon = (<Link to={backUrl} className="Modal-close">
					<Icon type="cross" />
				</Link>);
			closeButton.cover = (<Link to={backUrl} className="Modal-coverClose" />);


		}

		return (

			<div className={"Modal Modal--"+template+" "+active}>
				{modifier}

				<div className="Modal-scroll">
					<div className="Modal-content">
						<div className="Modal-body">

							{closeButton.icon}
							{content}
						
						</div>
					</div>
					{closeButton.cover}
				</div>

				<div className="Modal-bg" />

			</div>

		);

	}
}

export default withRouter(withSite(Modal));
