import React, {Component} from 'react'


import {
  Link
} from '../../components';

import {
  withSite
} from '../../utils';

class LoaderLink extends Component {
	click = (resume,el) => {

		let _this = this;

		let duration = this.props.duration || 400;
		let {site} = this.props;

		if(this.props.before) this.props.before(el);

		if(this.props.deactivate){

			let remove = document.querySelectorAll(this.props.deactivate+' .is-active');
			remove.forEach(el=>{

				el.classList.remove('is-active');

			})

		}
		// Add is active immediately to nav links
		if(this.props.navlink) el.classList.add('is-active');
		
		// Modifier
		site.modify('loading');
		
		setTimeout(function(){
			
			// Load the URL
			resume();

			// De-modify
			setTimeout(function(){
				site.modify('loaded');
				setTimeout(function(){
					site.deModify('loaded,loading');
				},100)
			},100)
		},duration)
			

	}
  render () {

  	let className = this.props.className || '';
  	let navlink = this.props.navlink;
  	let exact = this.props.exact || false;

    return (

      <Link to={this.props.to} className={className} navlink={navlink} exact={exact} before={this.click}>{this.props.children}</Link>
    )
  }
}

export default withSite(LoaderLink)
