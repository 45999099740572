import React, {Component} from 'react'
import Base from '../BaseComponent'

import {
  NavLink
} from 'react-router-dom';

class Navigation extends Component {

  render () {

    const isActive = (match, location) => {
      console.log('isActive',match,location)
    }

    let className = 'Navigation';

    if(this.props.modifiers) {

      this.props.modifiers.forEach((m) => className += ' '+className+'--'+m);

    }

    let hash = this.props.hash ? '#'+this.props.hash : '';



  	if(!this.props.items) return null;

  	let {items} = this.props;
  	let links = items.map((item,ix) =>(
        <li key={ix} className={ix == 0 ? 'first' : (ix == (items.length-1)) ? 'last' : null}><NavLink to={item.uri} exact={item.uri == '/'} activeClassName="is-active">{ item.title }</NavLink></li>
      ))

    return (
      <Base {...this.props} family="Navigation" tag="nav">
        <ul>
          { links }
        </ul>
      </Base>
    )
  }
}

export default Navigation
