import React, {Component} from 'react'
import Base from '../BaseComponent'
import Media from '../Media';

import Image from '../Image';
import Content from '../Content';

class Item extends Component {

  render () {

  	let {media,title} = this.props;

    return (
      <Base {...this.props} family="Item" >
	      <div className="Item-media">
	      	<Media id={media} />
	      </div>
	      <div className="Item-head">
	      	{title}
	      </div>

      </Base>
    )
  }
}

export default Item
