import React, {Component} from 'react'

import '../../helpers/Array';



class Shuffle extends Component {

  items = [];

  constructor(){

      super();
      this.state = {shuffled : false};
     

  }
  componentDidMount = () => {

      this.items = this.props.items ||[];
      this.items.shuffle();

      this.setState({shuffled:true});

  }

  render () {
    if(!this.state.shuffled) return null;
    return this.props.children(this.items);
      
  }
   
}

export default Shuffle
