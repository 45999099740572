import React, {Component} from 'react'
//import {TweenLite,Expo} from 'gsap';

/*

Add this to index.html
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/TweenMax.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/easing/EasePack.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/plugins/ScrollToPlugin.min.js"></script>    

*/

const TweenLite = window.TweenLite;
const Expo = window.Expo;



/*

JumpLink - scroll to an element on click

props:

to : {x:0,y:0} or '#elementId'
scroller: '#scrollableDiv'
duration: 1.2 (seconds)

*/

class JumpLink extends Component {

  animating=0;
  stopListening;

  constructor(props) {

      super(props);
      

  }
	
  componentDidMount() {


  }



  jump = () => {
      const _this = this;
      
     

      //if(_this.animating) return;

      _this.animating = 1;

      let scroller = (this.props.scroller) ? document.querySelector(this.props.scroller) : window;
      let duration = (this.props.hasOwnProperty('duration')) ? this.props.duration : 1.5;
      
      let offset = this.props.offset || 0;
      let to = this.props.to;

      if(offset && this.props.to.match('#')){

          let el = document.querySelector(this.props.to);
          let pos = el.offsetTop + window.innerHeight;
          to = pos - offset;

      }

      TweenLite.to(scroller,duration,{scrollTo:{y:to,autoKill:false},ease:Expo.easeInOut,onComplete:function(){
       
          _this.animating = 0;

      }});

  }

  render () {

    return (

      <a ref="el" className={this.props.className || ''} onClick={this.jump}>

        { this.props.children }

      </a>
    )
  }
}

export default JumpLink;
