import React, { Component } from 'react';

import conf from '../_core/_conf';

// GA Event Logger
import GoogleAnalytics from 'react-ga';

import {Icon,Form,Map,PageElement,ScrollTo,SiteModifier,FadeIn,Template,Window,Media,Section,Content,ModifierSection,Item} from '../_core/components';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';

import {Helmet} from "react-helmet";

class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];
		let settings = conf.get();

		let title = (entry.metaTitle) ? entry.metaTitle : entry.title;

	
		return (
			[
			<Helmet>
                <meta property="og:description" content={entry.metaDescription}/>
    			<meta name="description" content={entry.metaDescription} />
    			<meta name="twitter:description" content={entry.metaDescription} />
				<meta name="keywords" content={entry.metaKeywords} />
            </Helmet>,
			<PageElement name={'main'} id={entry.id} key={entry.id}>
				<Template modifiers={['contact']} title={title}>
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled,home'} />

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>{ entry.title }</h1>
						</div>
					</div>



					{/* Body */}
					<div className="Template-body">
						<div data-grid-of="2" data-mobile-grid-of="1" className="Template-mapGrid">
							<div>
								<div className="Container">
									<div className="Template-heading"><h1>{entry.title}</h1></div>
									<Content html={entry.content} />
									
								</div>
							</div>

							<div className="Template-mapCol">
								<div className="Template-mapWrap">
									<div className="Template-mapLink">
										<a className="Link Link--callToAction Link--mapLink" href={entry.mapUrl} target="_blank"><span><Icon type={'map'} glyph /></span> <span>View On Google</span></a>
									</div>
									{/* Map */}
									<Map className="Template-map" icon={{url:'/ui/img/icons/map-pin.svg',path:null,scaledSize:{width:100,height:100}}} zoom={17} styles={settings.MAPSTYLES} lat={entry.mapLatitude} lng={entry.mapLongitude}/>
								</div>
							</div>
						</div>

					</div>

					<hr />

					<div className="Template-section">
						<div className="Container">
							<div className="Grid--contact" data-grid-of="3" data-mobile-grid-of="1">
								<div>
									
									<Content html={entry.col1} />
										
								</div>
								<div>
									
									<Content html={entry.col2} />
										
								</div>
								<div>
								
									<Content html={entry.col3} modifier={'footer'} />
                                    <Project.Newsletter />
                                  
								</div>
							</div>
						</div>
					</div>

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks blocks={[...blocks]} entry={entry} modifiers={['debug']} />
						</div>
					</div>
				
				</Template>
			</PageElement>,
			// Modifiers: Use fallback
			<PageElement name={'modifiers'} useFallback />,
			// Blocks: Use fallback
			<PageElement name={'blocks'} useFallback />
			]
		)

	}
}

export default Default;
