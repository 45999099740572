import React, {Component} from 'react'
import { Media,Content,Parallax } from '../../_core/components'

// Utils
import * as BreakPoints from '../../_core/utils/Breakpoints';

class Layout extends Component {

  items = [];
  resizing = false;

  constructor() {
    super()

  }

  componentDidMount(){

    let _this = this;
    this.setHeight();

    // Run again as first try is sometimes out... :)
    setTimeout(()=>{_this.setHeight()},500);

    window.addEventListener('resize',this.setHeight);
   
  }
  
  componentWillUnmount() {
      window.removeEventListener('resize',this.setHeight);
    }

  setHeight = () => {

      if(this.resizing) return;

      this.resizing = true;

      let _this = this;

      let layout = this.refs.layout;

      let start = this.getAbsoluteBoundingRect(layout).top;
      
      let bottomBounds = 0;
      let topBounds = 0;
      let height = 0;
      
      // Set height to 0
      layout.style.height = '';

      
      this.items.forEach((ix) => {

            let item = this.refs['item'+ix];
            let rect = this.getAbsoluteBoundingRect(item);
            //let rect = item.getBoundingClientRect();
            let top = rect.top;
            let bottom = rect.bottom;

            bottomBounds = Math.max(bottomBounds, bottom);
            topBounds = Math.min(topBounds, top);

        });

        // Take off the original offset so the height is relative
        height = Math.abs(bottomBounds - start);
       
        // Set the height
        layout.style.height = height + 'px';
        //this.refs.layout.style.height = '';
      
        // Unlock with throttle
        setTimeout(()=>{_this.resizing = false},100);
  }

  getAbsoluteBoundingRect (el) {
    var doc  = document,
        win  = window,
        body = doc.body,

        // pageXOffset and pageYOffset work everywhere except IE <9.
        offsetX = win.pageXOffset !== undefined ? win.pageXOffset :
            (doc.documentElement || body.parentNode || body).scrollLeft,
        offsetY = win.pageYOffset !== undefined ? win.pageYOffset :
            (doc.documentElement || body.parentNode || body).scrollTop,

        rect = el.getBoundingClientRect();

    if (el !== body) {
        var parent = el.parentNode;

        // The element's rect will be affected by the scroll positions of
        // *all* of its scrollable parents, not just the window, so we have
        // to walk up the tree and collect every scroll offset. Good times.
        while (parent !== body) {
            offsetX += parent.scrollLeft;
            offsetY += parent.scrollTop;
            parent   = parent.parentNode;
        }
    }

    return {
        bottom: rect.bottom + offsetY,
        height: rect.height,
        left  : rect.left + offsetX,
        right : rect.right + offsetX,
        top   : rect.top + offsetY,
        width : rect.width
    };
}

  render () {

      let {layout} = this.props;
      this.items = [];

      let images = layout.images || [];
      let text = layout.text || [];
      
      // Image and text count
      let totalIx = 0;

      return (
        <div className="Layout" id="layout" ref="layout" data-layout-spaces={layout.spacesAfter} data-layout-padding-bottom={layout.paddingBottom}>

            {/* Image Items */}
            {images.map((item,ix)=>{

              if(!item.image || !item.image.length) return null;

              let caption = null;

              if(item.caption != ''){

                  caption =  (
                  <div className="Layout-caption">
                    <Content modifier="caption" html={item.caption} />
                  </div>)

              }

              let render = (
              <div key={item.image[0]} ref={'item'+totalIx}  className={"Layout-item Layout-item--image "} data-layout-mobile-size={item.mobileSize}  data-layout-colspan={item.width} data-layout-offset={item.offset} data-layout-top={(item.topOffset)}>
                {(()=>{

                    if(item.parallax && item.parallax != ''){

                        let pllx = 1;

                        if(item.parallax == 'slow') pllx = 0.9;
                        if(item.parallax == 'fast') pllx = 1.1;

                        return (

                        [<BreakPoints.Mobile>
                          <div className={'Layout-image--'+item.style}>
                            <Media id={item.image[0]} fluid={false} />
                          </div>
                          {caption}
                        </BreakPoints.Mobile>,
                        <BreakPoints.Default>
                          <Parallax parallax={pllx}>
                            <div className={'Layout-image--'+item.style}>
                              <Media id={item.image[0]} fluid={false} />
                            </div>
                            {caption}
                          </Parallax>
                        </BreakPoints.Default>]
                        )

                    }else{

                        return (
                        <div>
                        <div className={'Layout-image--'+item.style}>
                          <Media id={item.image[0]} fluid={false} />
                        </div>
                        {caption}
                        </div>
                        )

                    }

                })()} 
              </div>
            )

              this.items.push(totalIx);
              totalIx += 1;

              return render;

            })}

            {/* Text Items */}
            {text.map((item,ix)=>{

          

              let render = (
              <div key={'text'+ix} ref={'item'+totalIx}  className="Layout-item Layout-item--text" data-layout-colspan={item.width} data-layout-offset={item.offset} data-layout-top={((item.align == 'custom')?item.top:item.align)}>
                <Content modifier={item.textStyle} html={item.text} />
              </div>
            )


              this.items.push(totalIx);
              totalIx += 1;

              return render;

            })}

        </div>
      )
  }
}

export default Layout
