import React, {Component} from 'react';
import GoogleAnalytics from 'react-ga';






class ActionLogger extends Component {

  handleClick  = () => {

    //console.log('ACTION',this.props.category,this.props.action,this.props.label);

		// See https://github.com/react-ga/react-ga
  		GoogleAnalytics.event({
  			category: this.props.category,
  			action: this.props.action,
  			label: this.props.label
		});
 

  	}

  	componentWillMount = () =>{

      	// Allow immediate init (visibility, page load etc)
    	if(this.props.auto) this.handleClick();

    }


    render() {
      if(!this.props.children) return null;
    //const { children, ...propsToPass } = this.props

    //const child = React.Children.only(children);

    return <div {...this.props} onClick={this.handleClick}>{this.props.children}</div>;
    }
}

export default ActionLogger
