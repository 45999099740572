import React, {Component} from 'react'
import {RenderLoop} from '../../../_core/utils'

/*

Add this to index.html
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/TweenMax.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/easing/EasePack.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/plugins/ScrollToPlugin.min.js"></script>    

*/

const TweenLite = window.TweenLite;



class Parallax extends Component {
	lastScroll =0;
	startPos = {top:0,left:0,bottom:0,right:0};

	constructor(props) {
		super(props);
		this.state = {
			startPos : {top:0},
			pos:{top:0,left:0,bottom:0,right:0},
			pllx:0,
			progress:0
		}
	}

	componentDidMount = () => {

		if(this.props.parallax == 1) return;

		this.scroller = (this.props.scroller) ? document.querySelector(this.props.scroller) : window;
		this.scroll = (this.scroller.scrollTop) ? this.scroller.scrollTop : this.scroller.scrollY;

		this.scroll = (this.scroll) ? this.scroll : 0;

		let offset = this.refs.marker.getBoundingClientRect().top + this.scroll;


		//offset.top = offset.top + this.scroll
		this.setState({startPos:offset});
	}

	onFrame= () => {

		//console.log(this.site.classList.contains('Site--scrolling'))

		/*if(this.site.classList.contains('Site--scrolling')) {
			console.log('ignoring')
			return;
		} else {
			console.log('animating')
		}*/

		if(!this.scroller) return


		let scroll = (this.scroller.scrollTop) ? this.scroller.scrollTop : this.scroller.scrollY;



		if(scroll == this.lastScroll) return;


		this.lastScroll = scroll;


		let viewPortHeight = window.innerHeight;

		//let curPos = this.refs.marker.getBoundingClientRect();

		let origPos = this.state.startPos;



		let initialTravel = (origPos < viewPortHeight) ? 0 : origPos - viewPortHeight;

		let parallax = (this.props.parallax) ? this.props.parallax : 0.5;

		let travelled = scroll - initialTravel;

		let parallaxed = travelled * parallax;

		let diff = parallaxed - travelled;

		let move = -diff;

		let progress = travelled / (viewPortHeight);


		if(!origPos || travelled < 0) move = 0;


		TweenLite.set(this.refs.el,{y:move});



	}
  render () {

  	let _this = this;
  	if(this.props.parallax == 1){
  		return this.props.children;
  	}
    return (
    	<RenderLoop onFrame={this.onFrame}>
    		<div data-parallax-wrapper strength={this.props.parallax} ref={'wrapper'}>
	      	<div data-parallax-marker ref={'marker'} />
	      	<div data-parallax-content ref={'el'}>

	      	{this.props.children}

	      	</div>
	      	</div>
	    </RenderLoop>
    )
  }
}

export default Parallax
