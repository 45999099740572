import React, {Component} from 'react'
import { SiteModifier } from '../../_core/components'

class Burger extends Component {

  constructor() {
    super()

  }

  render () {

    return (
      <SiteModifier toggle={'menu'}>
        <div className="Burger">
          <span className="Burger-line Burger-line--top" />
          <span className="Burger-line Burger-line--middle" />
          <span className="Burger-line Burger-line--bottom" />
          <span className="Burger-line Burger-line--x1" />
          <span className="Burger-line Burger-line--x2" />
        </div>
      </SiteModifier>

    );

  }
}

export default Burger
