import React, {Component} from 'react'

class SingleInput extends Component {

  render () {




    return (
      <div className="Form-group">
      <label className="Form-label">{this.props.title}</label>
      <input
        className={this.props.className}
        name={this.props.name}
        type={this.props.inputType}
        value={this.props.value}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        onChange={this.props.onChange} />
      </div>
    );
  }
}

export default SingleInput
