import React, { Component } from 'react';

import {withStore} from '../../utils/Store';

const listeners =  {
    'ToolTip.setContent' : function(data){

        this.setContent(data);

    }
  }


class ToolTip extends Component {

  stopped = 0;
  ticker;
  mouseX=0;
  mouseY=0;
  tooltipId = 'tooltip';

  constructor(props) {
    super(props);
    this.state = {content:'',x:0,y:0,display:'none'};
  }
  
  /*
  
  Request animation frame...

*/

  loop = () => {
    
    if(this.stopped) return;
    this.onFrame();
    this.ticker = requestAnimationFrame(this.loop)
  }

  stopLoop = ()=> {
    this.stopped = 1;
    cancelAnimationFrame(this.ticker);


  }

  /*
  
  Ticker...

*/

onFrame = () =>{

	let x,y;

	if(this.props.ease){
     	x =  Math.round(this.state.x + (this.props.ease*(this.mouseX - this.state.x)));
    	y = Math.round(this.state.y + (this.props.ease*(this.mouseY - this.state.y)));
    }else{

    	x =  this.mouseX;
    	y = this.mouseY;

    }

    

    if(x != this.state.x || y != this.state.y) this.setState({x:x,y:y});
  
}

  

  /*
  
  Mounted - init the canvas and start the render loop...

  */

  componentDidMount() {

      window.addEventListener('mousemove',this.mouseMove);

      this.loop();
      const _this = this;
      // Event listener for set content
      //this.props.subscribe('ToolTip.setContent',(data) => _this.setContent(data));
      //this.props.trigger('ToolTip.get',this);

      this.props.store.store('ToolTip',this);
      //this.props.datastore.store('FOO','BAR');

  }

  /*
  
  Remove listeners on unmount...

  */

  componentWillUnmount() {
      window.removeEventListener('mousemove',this.mouseMove);
      this.stopLoop();
    }

    /*
  
  Store mouse coordinates

  */

    mouseMove = (e) => {

      this.mouseX = e.pageX - window.scrollX;
      this.mouseY = e.pageY - window.scrollY;


    }

    open = () => {

        this.setState({display:'block'});

    }

    close = () => {

        this.setState({display:'none'});

    }

    setContent = (content) => {

        this.setState({content:content});

    }

  
  render() {

    let origProps = {...this.props};
    delete origProps.dataChanged;

    let content = this.state.content;
    if(this.props.children){
      
      content = this.props.children;
      
    }

    return (
      <div ref="tooltip" style={{display:this.state.display,position:'fixed',left:this.state.x+'px',top:this.state.y+'px'}} {...origProps} >
        {content}
      </div>
    );
  }
}

export default withStore(ToolTip);
