import React, {Component} from 'react'

import moment from 'moment';

import 'moment-timezone';

/*

<DateFormat time={'2017-01-01'} format={'YYYY.MM.DD'} />

https://momentjs.com/

moment().format('MMMM Do YYYY, h:mm:ss a'); // May 1st 2018, 5:58:19 pm
moment().format('dddd');                    // Tuesday
moment().format("MMM Do YY");               // May 1st 18
moment().format('YYYY [escaped] YYYY');     // 2018 escaped 2018
moment().format();                          // 2018-05-01T17:58:19+01:00

Relative Time
moment("20111031", "YYYYMMDD").fromNow(); // 7 years ago
moment("20120620", "YYYYMMDD").fromNow(); // 6 years ago
moment().startOf('day').fromNow();        // 18 hours ago
moment().endOf('day').fromNow();          // in 6 hours
moment().startOf('hour').fromNow();       // an hour ago
Calendar Time
moment().subtract(10, 'days').calendar(); // 04/21/2018
moment().subtract(6, 'days').calendar();  // Last Wednesday at 5:58 PM
moment().subtract(3, 'days').calendar();  // Last Saturday at 5:58 PM
moment().subtract(1, 'days').calendar();  // Yesterday at 5:58 PM
moment().calendar();                      // Today at 5:58 PM
moment().add(1, 'days').calendar();       // Tomorrow at 5:58 PM
moment().add(3, 'days').calendar();       // Friday at 5:58 PM
moment().add(10, 'days').calendar();     

*/


//const today = moment().tz('America/New_York').format('e');
//const now = parseInt(moment().tz('America/New_York').format('Hmm'));


class DateFormat extends Component {


  render () {

  	let time = this.props.time || this.props.date;

  	let d = (time) ? moment(time) : moment();

    let format = this.props.format || 'MMM Do YYYY';

    let dateFrom = (this.props.labels) ? d.calendar(moment(this.props.reference),this.props.labels) : d.format(format);
    let dateTo = '';
    if(this.props.to){
    	let d2 = moment(this.props.to);
    	dateTo = (this.props.to != time) ? (' — ' + d2.format(format)) : '';
    }

    return dateFrom + dateTo;

  }
}

export default DateFormat;
