import React, {Component} from 'react'
import Matter from 'matter-js';

import Resize from '../../_core/utils/Resize'

const DECAY = 100; // Frames to render for after an action

class Blank extends Component {
	engine;
  renderer;
  offset = {};
  decay = DECAY;
	mouseX = -100;
	mouseY = -100;
  lastX = -100;
  lastY = -100;
	item;
	componentDidMount = () => {

    let moveRef = this.refs['wrapper'];
		let el = this.refs['el'];

		let w = el.offsetWidth;
		let h = el.offsetHeight;

    this.offset = el.getBoundingClientRect();

		// Matter.js module aliases
var Engine = Matter.Engine,
    World = Matter.World,
    Render = Matter.Render,
    Body = Matter.Body,
    Bodies = Matter.Bodies,
    Common = Matter.Common,
    Constraint = Matter.Constraint,
    Composites = Matter.Composites,
    MouseConstraint = Matter.MouseConstraint;

// create a Matter.js engine
var engine = Engine.create();

// create a renderer
var render = Render.create({
    engine:engine,
    element: el,
    options: {

    background : 'transparent',
      pixelRatio: 'auto',
      wireframes: false,
      showAngleIndicator: false,
       width: w,
       height: h
    }});


// gravity init
engine.world.gravity.x = 0;
engine.world.gravity.y = 0;

//add a mouse-controlled constraint
//var mouseConstraint = MouseConstraint.create(engine);
//World.add(engine.world, mouseConstraint);

let windowScale  = 1;
if(window.innerWidth < 1200) windowScale = 0.85;
if(window.innerWidth < 500) windowScale = 0.75;

let colsAcross = 60;
if(window.innerWidth < 1200) colsAcross = 40;
if(window.innerWidth < 500) colsAcross = 14;

colsAcross = Math.floor(w * 0.04);
if((colsAcross % 2) != 0) colsAcross -= 1; 

let padding = 40;

let spriteGutter = 5 * windowScale;
let guttersW = spriteGutter * colsAcross;

let availableWidth = (window.innerWidth - padding) - guttersW;
let screenDivision = (availableWidth / colsAcross);

let spriteH = screenDivision;
let spriteW = (spriteH * 0.32);

let spriteBoundingW = spriteH;

let fillHeight = this.props.fillHeight || 1;
let availableHeight = ((h*fillHeight) - padding)
let xDivisions = spriteBoundingW + spriteGutter;
let yDivisions = spriteH + spriteGutter;

let rows = Math.ceil(availableHeight/yDivisions);
let cols = Math.floor(w/xDivisions);



if((cols % 2) != 0) cols -= 1; 
if((rows % 2) != 0) rows -= 1; 
//cols += 2;

// How much height will the grid take up - number of rows x the spriteHeight, plus the gutter x number of rows minus two (top and bottom)
let heightUsed = Math.ceil((rows * spriteH) + (spriteGutter * (rows - 2)));
let heightDiff = h - heightUsed;

let yOffset = Math.round(heightDiff / 2);

// create a load of circle bodies
var stack = Composites.stack(20, yOffset, cols, rows, spriteGutter + (spriteW * 0.19), spriteGutter + (spriteW * 0.19), function(x, y, column, row) {

	let tex = Math.floor(Math.random() * 3) + 1  
	let scale = 0.1 * windowScale;


  let b =  Bodies.rectangle(x, y, spriteW, spriteH, { friction:0.5,frictionAir:0.05,inertia:0,restitution:0,render: {
    sprite: {
      texture: `/ui/img/texture/grain_${tex}.png`,
      xScale: scale,
      yScale: scale,
      xOffset: 0
      //Is there a 'width:' or 'height' property?  
    }
  }});



  let angle;

  	if(row % 2 == 0){
  		angle = (column % 2 == 0) ? 5.49 : 0.78;
	}else{
		angle = (column % 2 == 0) ?  0.78 : 5.49;

	}

  Body.setAngle(b,angle);

  return b;

});

let item = Bodies.circle(w/2, h/2, 30, { isStatic: true , render:{visible:false}});

this.item = item;

World.add(engine.world,item);

// add boundaries
//var offset = 5;
/*World.add(engine.world, [
  Bodies.rectangle(w/2,10, w, 5, { isStatic: true , render:{visible:false}}),
  Bodies.rectangle(w-10, h/2, 5, h, { isStatic: true , render:{visible:false}}),
  Bodies.rectangle(10 , h/2, 5, h, { isStatic: true, render:{visible:false} }),
  Bodies.rectangle(w/2, h-10, w, 5, { isStatic: true, render:{visible:false} })
]);*/


// add all of the bodies to the world
World.add(engine.world, stack);

// run the engine
//Engine.run(engine);


// run the renderer
//Render.run(render);

this.renderer = render;
this.engine = engine;

//window.addEventListener('resize',this.resize);
	moveRef.addEventListener('mousemove',this.mouseMove);
	moveRef.addEventListener('touchmove',this.touchMove);

	this.loop();

	}


	/*
	
	Remove listeners on unmount...

	*/

	componentWillUnmount() {
	    //window.removeEventListener(this.resize);
        let moveRef = this.refs['wrapper'];
	     moveRef.removeEventListener('mousemove',this.mouseMove);
        moveRef.removeEventListener('touchmove',this.touchMove);
	    this.stopLoop();
	  }

	   mouseMove = (e) => {

      let offset = this.refs['el'].getBoundingClientRect();

      this.decay = DECAY;

      this.lastX = this.mouseX;
      this.lastY = this.mouseY;

  		this.mouseX = e.pageX;
  		this.mouseY = (e.pageY - (offset.top + window.scrollY));


  	}

  	loop = () => {
    
    if(this.stopped) return;
    this.onFrame();
    this.ticker = requestAnimationFrame(this.loop)
  }

  stopLoop = ()=> {
    this.stopped = 1;
    cancelAnimationFrame(this.ticker);


  }

  onFrame = () => {
  		


      if((this.mouseY == this.lastY) && (this.mouseX == this.lastX) && !this.decay){
        
        return;
      }

      Matter.Render.run(this.renderer);

      this.decay--;
      
     this.lastX = this.mouseX;
     this.lastY = this.mouseY;

  		var Engine = Matter.Engine,
    World = Matter.World,
    Body = Matter.Body,
    Bodies = Matter.Bodies,
    Common = Matter.Common,
    Constraint = Matter.Constraint,
    Composites = Matter.Composites,
    MouseConstraint = Matter.MouseConstraint;

    	let xVelocity = this.mouseX - this.item.position.x;
    	let yVelocity =  this.mouseY - this.item.position.y 

    	xVelocity = Math.min(xVelocity,1);
    	yVelocity = Math.min(yVelocity,1);

    	Body.setVelocity(this.item, { x:xVelocity, y: yVelocity });
  		Body.setPosition(this.item,{

  			x:this.mouseX,
  			y:this.mouseY

  		});

  		Engine.update(this.engine,1000/60);

      Matter.Render.stop(this.renderer);

  }

  	/*
	
	Store touch coordinates as mouse

	*/

	  touchMove = (e) => {

	  	var touch = e.touches[0] // reference first touch point for this event
       let offset = this.refs['el'].getBoundingClientRect();

      this.decay = DECAY;
      
      this.lastX = this.mouseX;
      this.lastY = this.mouseY;

  		this.mouseX = touch.clientX;
  		this.mouseY = (touch.clientY - (offset.top + window.scrollY));

  		if(this.mouseY > window.innerHeight) this.mouseY = window.innerHeight;



  	}

    resize = () => {

      let el = this.refs['el'];

      let w = el.offsetWidth;
      let h = el.offsetHeight;

      
      this.engine.world.bounds.max.x = w ;
      this.engine.world.bounds.max.y = h ;

      
      this.renderer.canvas.width =w;
      this.renderer.canvas.height = h;

       //Matter.Render.setPixelRatio(this.renderer,2);
       Matter.Render.world(this.renderer);

    }

  render () {

    return (
      
      <div data-layout="fluid" ref="wrapper">
        <div data-layout="fluid" ref="el" />
      </div>
   
    )
  }
}

export default Blank
