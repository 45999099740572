import React, { Component } from 'react';


class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {



		return (
			<div>Default Modal</div>
		);

	}
}

export default Default;
