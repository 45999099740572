import React, { Component } from 'react';

import {PageElement,ScrollTo,SiteModifier,FadeIn,Template,Window,Media,Section,Content,ModifierSection,Item} from '../_core/components';

// Models
import {Asset,Entry,Data} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		return (
			[
			<PageElement name={'main'} id={404}>
				<Template title={'Page Not Found'}>
					
					{/* Reset styles */}
					<SiteModifier auto demodifier={'scrolled,home'} />

					{/* Template */}
					
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>Page Not Found</h1>
						</div>
					</div>

					{/* Body */}
					<div className="Template-body">
						<div className="Container">
							<Content html={"<h1>Page Not Found</h1><p>Sorry, We can't find that page.<br />You may have followed an out of date link.</p><p>If you feel there should be a page here, please <a href='/contact'>Contact us</a></p>"} />
						</div>
					</div>
			
				</Template>
			</PageElement>,
			// Modifiers: Use fallback
			<PageElement name={'modifiers'} useFallback />,
			// Blocks: Use fallback
			<PageElement name={'blocks'} useFallback />
			]
		)


	}
}

export default Default;
